import { Button, Form, Input } from 'antd';
import Link from 'antd/es/typography/Link';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LogoLogin from '../../../asset/images/LogoLogin.svg';
import { loginThunk } from '../../../redux/reducers/authSlice';
import { getCookie } from '../../../utility/utils';
import './style.scss';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, isLogin } = useSelector((state) => state.auth);

  useEffect(()=>{
    if (isLogin) {
      navigate('/request');
    }
  },[isLogin])

  const handleSubmit = (values) => {
    dispatch(loginThunk(values));
  };

  return (
    <div className="loginPage">
      <div className="content">
        <div className="logo">
          <img src={LogoLogin} />
        </div>
        <Form name="login" layout="vertical" colon={false} onFinish={handleSubmit}>
          <Form.Item label="User ID" name="username">
            <Input size="large" />
          </Form.Item>
          <Form.Item label="Password" name="password" style={{ marginBottom: '8px' }}>
            <Input.Password size="large" />
          </Form.Item>
          <div className="text-14 gray-900 fw-6 mb-4 text-end">Forgot Password</div>
          <Button htmlType="submit" block type="primary" loading={loading} size='large'>
            Sign in
          </Button>
          <div className="text-14 gray-700 fw-4 mt-4 text-center">
            Don’t have an account? <b onClick={() => navigate('/sign-up')}>Sign Up</b>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Login;
