import { Navigate } from 'react-router-dom';
import HTVLayout from '../common/layout/HTVLayout';
import HTVLayout2 from '../common/layout/HTVLayout2';
import PurchaseProductPage from '../views/pages/case';
import CaseDetail from '../views/pages/case/CaseDetail';
import CreateNewCase from '../views/pages/case/CreateNewCase';
import CommissionHistoryPage from '../views/pages/commission-history/CommissionHistoryPage';
import NotificationPage from '../views/pages/notification/NotificationPage';
import NotificationSetting from '../views/pages/notification/NotificationSetting';
import ProfilePage from '../views/pages/profile/ProfilePage';
import RefundInvoice from '../views/pages/refund-invoice';
import ReplacementRequest from '../views/pages/replacement-request';
import RequestPage from '../views/pages/request/RequestPage';
import PrivateRoute from './PrivateRoute';
import WishListPage from '../views/pages/wishlist-page/WishListPage';
export { default as PrivateRoute } from './PrivateRoute';
export { default as PublicRoutes } from './PublicRoutes';

const AllPrivateRoutes = [
  {
    path: '/',
    index: true,
    element: <Navigate to="/request" replace />
  },
  {
    path: 'request',
    element: <RequestPage />
  },
  {
    path: 'case',
    element: <PurchaseProductPage />
  },
  {
    path: 'commission-history',
    element: <CommissionHistoryPage />
  },
  {
    path: 'refund-invoice',
    element: <RefundInvoice />
  },
  {
    path: 'replacement-invoice',
    element: <ReplacementRequest />
  },
  {
    path: 'create-case',
    element: <CreateNewCase />
  },
  {
    path: 'edit-case',
    element: <CreateNewCase action={'edit'} />
  },
  {
    path: 'case/detail',
    element: <CaseDetail />
  },
  {
    path: 'notification',
    element: <NotificationPage />
  },
  {
    path: 'notification/setting',
    element: <NotificationSetting />
  },
  {
    path: 'profile',
    element: <ProfilePage />
  },
  {
    path: 'wish-list',
    element: <WishListPage />
  },
  
    // {
  //   path: "/login",
  //   element: token ? (
  //     <Navigate replace to='/request' />
  //   ) : (
  //     <Login />
  //   ),
  //   children: [{ path: "/login", element: <Login /> }],
  // },
  // {
  //   path: "*",
  //   element: <BlankLayout />,
  //   children: [{ path: "*", element: <Error /> }],
  // },
];


export const AllProtectedRoute = [
  {
    path: '/',
    element: (
      <PrivateRoute>
        <HTVLayout />
      </PrivateRoute>
    ),
    children: [...AllPrivateRoutes]
  },
];
