import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Input, Row, Select } from 'antd';
import React, { useEffect } from 'react';
import { RedoOutlined } from '@ant-design/icons';
import { REQUESTED_SORT_BY_DEFAULT, REQUESTED_TYPE_DEFAULT } from '../../../../common/utilities/constants';
import RequestFilter from './RequestFilter';
import { useDispatch, useSelector } from 'react-redux';
import { changePage, closeSearchDrawer, fetchRequestThunk, fetchSpecialOrderThunk } from '../../../../redux/reducers/requestSlice';

const SearchFilter = ({ onClose, open, onSaveSearchData, searchData = {}, in_wishlist = false }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const initialValues = {
    sort: searchData.sort,
    keyword_type: searchData.keyword_type,
    size: searchData.size,
  }

  useEffect(()=>{
    if(searchData?.brand_id){
      form.setFieldValue('brand_id', searchData?.brand_id)
    }
  },[])


  const handleSearch = (values) => {
    dispatch(fetchRequestThunk({ ...values, in_wishlist, page: 1 }));
    dispatch(
      fetchSpecialOrderThunk({
        ...values,
        page: 1,
        size: 100,
      })
    );
    dispatch(closeSearchDrawer());
    dispatch(changePage(1));
  };

  const handleResetForm = () => {
    form.resetFields()
    // dispatch(fetchRequestThunk({ page, size: 1 }));
  };

  const handleClose = () => {
    onClose();
    onSaveSearchData(form.getFieldsValue());
  };

  return (
    <Drawer
      title={false}
      placement={'top'}
      closable={false}
      onClose={handleClose}
      open={open}
      key={'search'}
      rootClassName="search-drawer"
      contentWrapperStyle={{
        height: '280px',
        overflow: 'scroll'
      }}
      maskClosable={false}>
      <Form name="filter-search" form={form} onFinish={handleSearch} initialValues={initialValues}>
        <Row justify="space-between" gutter={[10]}>
          <Col span={10}>
            <Form.Item name="keyword_type">
              <Select size="large" options={REQUESTED_TYPE_DEFAULT} placeholder="Product ID" />
            </Form.Item>
          </Col>
          <Col span={7}>
            <RequestFilter />
          </Col>
          <Col span={7}>
            <Button size="large" className="w-100" icon={<RedoOutlined />} onClick={handleResetForm}>
              Reset
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item name="keyword">
              <Input suffix={<SearchOutlined />} placeholder="Search" size="large" className="w-100" />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col span={11} className="row-per-page">
            <Form.Item name="size" className="select">
              <Select
                options={[
                  {
                    value: 10,
                    label: '10'
                  },
                  {
                    value: 20,
                    label: '20'
                  },
                  {
                    value: 50,
                    label: '50'
                  },
                  {
                    value: 100,
                    label: '100'
                  }
                ]}
              />
            </Form.Item>{' '}
            <p>row per pages</p>
          </Col>
          <Col span={11} className="sort-by">
            <p>Sort by</p>{' '}
            <Form.Item name="sort" className="select">
              <Select options={REQUESTED_SORT_BY_DEFAULT} />
            </Form.Item>
          </Col>
        </Row>
        <div className="d-flex justify-content-end mt-2">
          <Button onClick={handleClose} className="me-1">
            Close
          </Button>
          <Button htmlType="submit" type="primary">
            Search
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};

export default SearchFilter;
