import { Button, Form, Input, message, Select, Space, Spin, Statistic } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import HeaderMD2 from '../../../common/layout/HeaderMD2';
import { useDispatch, useSelector } from 'react-redux';
import { apiClientV2 } from '../../../services/axios';
import { fetchUserInfoThunk } from '../../../redux/reducers/authSlice';
import { FranceFlag, KoreanFlag } from '../../../asset/icons/Icons';

const ProfilePage = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isUpdatePhoneNumber, setIsUpdatePhoneNumber] = useState(false);
  const [isCountDown, setIsCountDown] = useState(false);
  const [countDownTime, setCountDownTime] = useState(null);
  const [certified, setCertified] = useState(false);
  const [otpCertied, setOtpCertied] = useState(false);
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const { userInfo, getInfoLoading } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(fetchUserInfoThunk());
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      full_name: userInfo?.full_name || '',
      country: userInfo?.country || '',
      areaCode: userInfo?.country || 'korean',
      phone: userInfo?.phone || ''
    });
  }, [userInfo]);

  const MOBILE_TYPE_OPTIONS = [
    {
      label: 'Korean +82',
      value: 'korean',
      code: '+82'
    },
    {
      label: 'France +33',
      value: 'france',
      code: '+33'
    }
  ];

  const updateProfile = async (body) => {
    setLoadingUpdate(true);
    try {
      const res = await apiClientV2.put('/user/me', body);
      dispatch(fetchUserInfoThunk());
      setIsUpdatePhoneNumber(false);
      setLoadingUpdate(false);
      message.success('Update Profile successfully');
      form.setFieldsValue({
        otp: undefined
      });
    } catch (error) {
      setLoadingUpdate(false);
      message.error(error.response.data?.message || 'Update Profile Failed');
    }
  };

  const authentication = async (body) => {
    setLoadingAuth(true);
    try {
      const res = await apiClientV2.post('/user/me/phone/certify', body);
      setCertified(true);
      setLoadingAuth(false);
      setIsCountDown(true);
      setCountDownTime(Date.now() + 180 * 1000);
    } catch (error) {
      setLoadingAuth(false);
      message.error(error.response.data?.message || 'Certify Failed');
    }
  };

  const handleCertified = () => {
    const { phone, areaCode } = form.getFieldsValue();
    const body = {
      phone: getPhoneNumber(areaCode, phone)
    };
    authentication(body);
  };

  const handleFinish = async (values) => {
    const { full_name, country, phone, otp, areaCode } = values;
    const body = {
      full_name,
      country,
      phone: getPhoneNumber(areaCode, phone),
      otp
    };
    updateProfile(body);
  };

  // const handleAuthentication = () => {
  //   const { phone, areaCode } = form.getFieldsValue();
  //   const body = {
  //     phone: getPhoneNumber(areaCode, phone)
  //   };
  //   authentication(body);
  // };

  const getPhoneNumber = (code, phone) => {
    if (!phone?.includes('+')) {
      const codeValue = MOBILE_TYPE_OPTIONS.filter((item) => item.value === code)?.[0]?.code;
      const subPhone = phone.slice(1, phone.length);
      return codeValue + subPhone;
    } else {
      return phone;
    }
  };

  const onClickChangePhoneNumber = () => {
    form.setFieldsValue({
      phone: undefined
    });
    setIsUpdatePhoneNumber(true);
  };

  return (
    <>
      <HeaderMD2 title={'Profile'} />
      <Content
        style={{
          minHeight: 380,
          backgroundColor: '#fff',
          paddingBottom: '10px',
          marginTop: '30px'
        }}>
        <Form
          form={form}
          name="profile"
          layout="vertical"
          initialValues={{
            full_name: userInfo?.full_name || '',
            country: userInfo?.country || '',
            areaCode: userInfo?.country || 'korean',
            phone: userInfo?.phone || ''
          }}
          onFinish={handleFinish}>
          <div className="container-page">
            <Spin tip="Loading" spinning={getInfoLoading}>
              <Form.Item name="full_name" label={<b className="gray-700">Name</b>}>
                <Input size="large" disabled />
              </Form.Item>
              <Form.Item name="country" label={<b className="gray-700">Country</b>}>
                <Input size="large" disabled />
              </Form.Item>
              <Form.Item name="areaCode" label={<b className="gray-700">Phone Number</b>}>
                <Select size="large" disabled={!isUpdatePhoneNumber}>
                  <Select.Option value="korean" label="+82 Korean">
                    <Space>
                      {/* <KoreanFlag /> */}
                      +82 Korean
                    </Space>
                  </Select.Option>
                  <Select.Option value="france" label="+33 France">
                    <Space>
                      {/* <KoreanFlag /> */}
                      +33 France
                    </Space>
                  </Select.Option>
                </Select>
              </Form.Item>
              <Space.Compact style={{ width: '100%' }} size="large">
                <Form.Item name="phone" className="w-100">
                  <Input
                    size="large"
                    disabled={!isUpdatePhoneNumber}
                    placeholder="Enter phone number"
                    className="w-100"
                  />
                </Form.Item>
                {isUpdatePhoneNumber && (
                  <Button
                    loading={loadingAuth}
                    size="large"
                    type="primary"
                    className="green-btn"
                    onClick={handleCertified}>
                    {isCountDown ? 'Resend' : 'Certified'}
                  </Button>
                )}
              </Space.Compact>
              {isUpdatePhoneNumber && (
                <Form.Item name="otp">
                  <Input
                    size="large"
                    placeholder="Enter certified number"
                    suffix={
                      isCountDown && (
                        <Statistic.Countdown
                          valueStyle={{
                            fontSize: '16px',
                            color: '#4BBF9F'
                          }}
                          format="mm:ss"
                          value={countDownTime}
                          onFinish={() => setCertified(false)}
                        />
                      )
                    }
                  />
                </Form.Item>
              )}
              {!isUpdatePhoneNumber ? (
                <Button type="primary" size="large" className="green-btn" block onClick={onClickChangePhoneNumber}>
                  Change phone number
                </Button>
              ) : (
                <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.otp !== curValues.otp}>
                  {({ getFieldValue }) => {
                    if (getFieldValue('otp')?.length > 5 && getFieldValue('otp')?.length < 9) {
                      setOtpCertied(true);
                    } else {
                      setOtpCertied(false);
                    }
                    return (
                      <Button
                        type="primary"
                        block
                        size="large"
                        className="green-btn"
                        // onClick={handleAuthentication}
                        disabled={!(certified && getFieldValue('otp')?.length > 5 && getFieldValue('otp')?.length < 9)}>
                        Authentication completed
                      </Button>
                    );
                  }}
                </Form.Item>
              )}
            </Spin>
          </div>
          <Button
            htmlType="submit"
            loading={loadingUpdate}
            // onClick={handleSubmit}
            className="submit-new-btn"
            block
            type="primary"
            disabled={!(certified && otpCertied)}>
            Save
          </Button>
        </Form>
      </Content>
    </>
  );
};

export default ProfilePage;
