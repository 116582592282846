import { Drawer } from 'antd';
import React, { useState, useEffect } from 'react';
import { apiClientV2 } from '../../../services/axios';
import { NoticeIcon } from '../../../asset/icons/Icons';

const NoticeButton = ({ pathname }) => {
  const [isOpenDrawerNotice, setIsOpenDrawerNotice] = useState(false);
  const [noticeContent, setNoticeContent] = useState([]);
  useEffect(() => {
    getNotice();
  }, [pathname]);

  const fetchNotices = async ({
    page = 1,
    size = 50,
    keyword = '',
    keyword_type = 'all',
    sort = '-created_at',
    exactly = false,
  } = {}) => {
    try {
      const response = await apiClientV2.get('/notice', {
        params: {
          page,
          size,
          keyword,
          keyword_type,
          sort,
          exactly,
        },
      });
      if (response.status === 200) {
        return response.data;
      }
      return null;
    } catch (e) {
      return null;
    }
  };

  const getNotice = async () => {
    const notice = await fetchNotices({
      page: 1,
      size: 100,
      keyword_type: 'page',
      keyword: pathname,
      exactly: true
    }).then(({ resources = [] }) => resources.map((r) => r.content));
    setNoticeContent(notice);
  };

  return noticeContent.length > 0 && (
    <>
      <div className="notice-float-btn" onClick={() => setIsOpenDrawerNotice(true)}>
        <NoticeIcon /> <span className="ms-1">Notice</span>
      </div>
      <Drawer
        open={isOpenDrawerNotice}
        title={<b style={{ fontSize: '18px' }}>Notice</b>}
        onClose={() => setIsOpenDrawerNotice(false)}
        placement="bottom"
        style={{
          padding: '5px',
          overlayBlur: 0,
          overlayOpacity: 0.5
        }}
        size={400}
        closable={false}>
        {noticeContent.map((item, idx) => (
          <p key={idx}>{item}</p>
        ))}
      </Drawer>
    </>
  );
};

export default NoticeButton;
