import { CloseOutlined } from '@ant-design/icons';
import { Button, Drawer, Modal } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { openSearchDrawer } from '../../../../redux/reducers/requestSlice';
import RequestPage from '../../../pages/request/RequestPage';
import '../style.scss';
import ActionIcon from '../../../../common/components/ActionIcons';
import { HeartAll, HeartAllFill } from '../../../../asset/icons/Icons';
import { Search } from 'react-feather';

const RequestSearchModal = ({ isOpen, onCancel, searchData, onAutoFill, productKey }) => {
  const [isWishList, setIsWishList] = useState(false);
  const dispatch = useDispatch();
  return (
    <>
      <Modal
        title={
          <div className="title-modal">
            <div className="d-flex align-items-center">
              <CloseOutlined style={{ fontSize: '20px' }} onClick={onCancel} />
              <div className="ms-2 title">Requested</div>
            </div>
            <div>
              <ActionIcon
                style={{ padding: '0px', marginRight: '10px' }}
                icon={<Search size="26px" />}
                onClick={() => dispatch(openSearchDrawer())}
              />
              {isWishList ? (
                <ActionIcon
                  icon={<HeartAllFill />}
                  style={{ padding: '0px' }}
                  onClick={() => setIsWishList((state) => !state)}></ActionIcon>
              ) : (
                <ActionIcon
                  icon={<HeartAll />}
                  style={{ position: 'relative', padding: '0px' }}
                  onClick={() => setIsWishList((state) => !state)}>
                  <span style={{ position: 'absolute', color: '#FFFFFF', fontSize: '12px', left: '9px' }}>All</span>
                </ActionIcon>
              )}
            </div>
            {/* <Button type="primary" onClick={() => dispatch(openSearchDrawer())}>
              Search
            </Button> */}
          </div>
        }
        open={isOpen}
        onCancel={onCancel}
        style={{ top: 20, height: '80vh', maxWidth: '100vw' }}
        bodyStyle={{
          height: '86vh',
          overflow: 'auto'
        }}
        width={'100vw'}
        footer={false}
        closable={false}
        wrapClassName="modal-search-requested">
        <RequestPage isHideHeader={true} onAutoFill={onAutoFill} productKey={productKey} searchDataProps={{...searchData, in_wishlist: isWishList}} />
      </Modal>

      {/* <Drawer
        className="modal-search-requested"
        title={
          <div className="title-modal">
            <div className="ms-2 title">Requested</div>
            <Button type="primary" onClick={() => dispatch(openSearchDrawer())}>
              Search
            </Button>
          </div>
        }
        bodyStyle={{
          padding: '0 0 10px 0'
        }}
        placement={'bottom'}
        onClose={onCancel}
        open={isOpen}
        contentWrapperStyle={{
          height: '96vh',
          overflow: 'scroll'
        }}>
        <RequestPage isHideHeader={true} />
      </Drawer> */}
    </>
  );
};

export default RequestSearchModal;
