import Login from '../views/pages/login';
import RequireStep from '../views/pages/login/sign-up/RequireStep';
import CertifyPhone from '../views/pages/login/sign-up/CertifyPhone';
import ThankYouPage from '../views/pages/login/sign-up/ThankYouPage';
import NotFound from '../views/pages/not-found';
import SignUpPage from '../views/pages/login/sign-up/SignUpPage';

const PublicRoutes = [
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/sign-up',
    element: <SignUpPage />
  },
  {
    path: 'require-step',
    element: <RequireStep />
  },
  {
    path: 'thank-you',
    element: <ThankYouPage />
  },
  {
    path: '/certify-phone',
    element: <CertifyPhone />
  },
  {
    path: '/*',
    element: <NotFound />
  }
];
export default PublicRoutes;
