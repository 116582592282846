import { useRoutes } from 'react-router-dom';
import { AllProtectedRoute, PublicRoutes } from './routes';
import './App.css';
import './antd-rewrite.css'
import 'antd/dist/reset.css';

function App() {
  const element = useRoutes([...PublicRoutes, ...AllProtectedRoute]);
  return element;
}

export default App;
