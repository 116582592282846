import { Select } from 'antd';
import { useCallback } from 'react';
import AsyncSelect from '../../../../common/components/AsyncSelect/AsyncSelect';
import { fetchCurrency } from '../api';

export default function CurrencyAsyncSelect({ ...props }) {
  const fetchSupplierSelect = useCallback(async query => {
    const data = await fetchCurrency({ page: 1, size: 500 }).then(({ resources = [] }) =>
      resources
        .map(r => ({
          value: r.id,
          label: r.name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    );
    return data;
  },[]);

  return <AsyncSelect placeholder="Currency" allowClear fetchApi={fetchSupplierSelect} {...props} />;
}
