import { Button, Divider, Form, Layout, message, Modal, Typography, Spin } from 'antd';
import { every, isArray, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import HeaderMD2 from '../../../common/layout/HeaderMD2';
import apiClient from '../../../services/axios';
import CaseInfo from '../../features/purchased/components/CaseInfo';
import ProductInfo from '../../features/purchased/components/ProductInfo';
import TotalForm from '../../features/purchased/components/TotalForm';
import dayjs from 'dayjs';
import { ChevronLeft, X } from 'react-feather';
import ActionIcon from '../../../common/components/ActionIcons';
const { Content } = Layout;

const CreateNewCase = ({action = 'new'}) => {
  const [form] = Form.useForm();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const caseId = action === 'edit' ? searchParams.get('id') : undefined;

  function getParams (s) {
    const params = { step: s };
    if (caseId) {
      params['id'] = caseId;
    }
    return params;
  }

  useEffect(() => {
    setSearchParams(getParams('1'));
  }, []);

  useEffect(() => {
    if (caseId) {
      fetchCase(caseId);
    }
  }, [caseId]);

  const fetchCase = async (id) => {
    setLoading(true);
    try {
      const { data } = await apiClient.get(`/case/${id}`);
      if (data) {
        form.setFieldsValue({
          ...data,
          supplier_brand_id: data.supplier.brand_id,
          products: data.products?.map((item) => ({
            // ...item,
            id: item.id,
            product_id: item.product_id,
            product_variant_id: item.product_variant_id,
            quantity: item.quantity,
            vat_percent: item.vat_percent,
            gross_price: item.gross_price,
            discount: item.discount,
            pictures: item.pictures,
            price: item.product?.price,
            memo: item.memo,
            product_name: item.product?.name_en?.trim() ? item.product?.name_en : item.product?.name_ko || ''
          }))
        });
      } else {
        message.error('error');
      }
    } catch (error) {
      message.error(error.response?.data?.message || 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchParams.get('step')) {
      setStep(+searchParams.get('step'));
    }
  }, [searchParams.get('step')]);

  const handleToStep2 = () => {
    handleScrollTop();
    setSearchParams(getParams('2'));
  };
  const handleToStep3 = () => {
    handleScrollTop();
    setSearchParams(getParams('3'));
  };

  const handleToStep1 = () => {
    handleScrollTop();
    setSearchParams(getParams('1'));
  };

  const handleValidateForm = (values) => {

    const isValidate = every(values, (value, key) => {
      if (['shipping_company', 'tracking_number', 'by_online'].includes(key)) {
        return true;
      }
      if (!value && value !== '') {
        return false;
      } else {
        return true;
      }
    });
    return !isValidate;
  };

  const handleValidateProducts = (values) => {
    if (values.length > 0) {
      let quantity = 0;
      let pictures = [];
      for (const value of values) {
        for (const key in value) {
          if (key === 'memo') {
            continue;
          }
          if (!value[key] && !(value[key] == 0)) {
            return false;
          } else if (isArray(value[key]) && isEmpty(value[key])) {
            return false;
          }
          if (key === 'quantity') {
            quantity = value[key];
          }
          if (key === 'pictures') {
            pictures = value[key];
          }
        }
      }
      let validated = false;
      pictures?.map((p) => {
        if (p?.group && +p?.group === +quantity) {
          validated = true;
        }
      });
      return validated;
    } else {
      return false;
    }
  };

  const onSubmit = async () => {
    const body = form.getFieldsValue();
    setLoading(true);
    try {
      caseId ? await apiClient.put(`/case/${caseId}`, body) : await apiClient.post('/case', body);
      message.success('Successfully');
      setLoading(false);
      navigate('/case');
    } catch (error) {
      message.error(error?.response?.data?.message || 'Error');
      setLoading(false);
    }
  };

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const handleClickSubmitBtn = async () => {
    const { products } = form.getFieldValue();
    const noReqProducts = products.map((prod, idx) => ({ ...prod, idx })).filter((prod) => !prod.in_prod_request); // Products that not being requested
    if (noReqProducts.length === 0) {
      onSubmit();
    } else {
      Modal.confirm({
        title: false,
        content: (
          <div>
            <div className="text-16 fw-7 gray-900">
              <div>{`It's not in the request`}</div>
              <div>Do you still want to submit it?</div>
            </div>
            {noReqProducts.map((prod) => {
              return (
                <div key={`no_req_product_${prod.idx}`}>
                  <br></br>
                  <div className="text-16 fw-7 gray-900">
                    <div>{`Product.${prod.idx + 1}`}</div>
                  </div>
                  <div>{`Product Name: ${prod.product_name}`}</div>
                  <div>{`Product ID: ${prod.product_id}`}</div>
                </div>
              );
            })}
          </div>
        ),
        onCancel: () => {},
        onOk: onSubmit,
        centered: true,
        icon: null,
        cancelText: 'Close',
        okText: 'Submit',
        okButtonProps: {
          type: 'primary',
          style: {
            marginRight: '4%',
            width: '46%',
            backgroundColor: '#10264D'
          }
        },
        cancelButtonProps: {
          style: {
            width: '46%'
          }
        }
      });
    }
  };

  return (
    <>
      <HeaderMD2
        isHideLeftIcon={step !== 3}
        rightIems={(step === 1 || step === 2) && <ActionIcon icon={<X />} onClick={() => navigate('/case')} />}
        title={action === 'edit' ? 'Edit Case' : 'Submit New Case'}
        onNavigate={handleToStep2}
      />
      <Content
        style={{
          minHeight: 380,
          backgroundColor: '#fff',
          paddingBottom: '10px'
        }}>
        <div className="create-new-case">
          <Form
            name="test"
            layout="vertical"
            form={form}
            // onFinish={onSubmit}
            disabled={step === 3}
            initialValues={{
              invoice_date: dayjs(new Date()),
              delivery_location: 'france',
              currency_id: 3,
              memo: '',
              shipping_company: null,
              tracking_number: null,
              by_online: false,
              products: [
                {
                  product_id: null,
                  product_variant_id: null,
                  quantity: null,
                  vat_percent: 20,
                  gross_price: null,
                  discount: 0,
                  pictures: [],
                  in_prod_request: false
                }
              ]
            }}>
            {step === 1 && (
              <>
                <div className="container-page">
                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <Typography.Title level={3} className="fw-7">
                      Case information
                    </Typography.Title>
                    <div>
                      <span style={{ color: 'red' }}>*</span> Essential
                    </div>
                  </div>
                  <CaseInfo form={form} />
                </div>
                <Form.Item shouldUpdate>
                  {({ getFieldsValue }) => {
                    return (
                      <Button
                        disabled={handleValidateForm(getFieldsValue())}
                        onClick={handleToStep2}
                        className="fixed-bottom-btn"
                        block
                        type="primary">
                        Add Product
                      </Button>
                    );
                  }}
                </Form.Item>
              </>
            )}
            {step === 2 && (
              <div>
                <Spin tip="Loading..." spinning={loading}>
                  <div className="container-page">
                    <div className="d-flex align-items-center mt-3">
                      <ActionIcon icon={<ChevronLeft />} onClick={handleToStep1} className="mb-2" />
                      <Typography.Title level={3} className="fw-7">
                        Product information
                      </Typography.Title>
                    </div>
                    <ProductInfo mode="edit" onValidate={handleValidateProducts} form={form} />
                    <Divider />
                    <TotalForm />
                  </div>
                  <Form.Item shouldUpdate>
                    {({ getFieldValue }) => {
                      return (
                        <Button
                          className="fixed-bottom-btn"
                          block
                          type="primary"
                          onClick={handleToStep3}
                          disabled={!handleValidateProducts(getFieldValue('products'))}>
                          Next
                        </Button>
                      );
                    }}
                  </Form.Item>
                </Spin>
              </div>
            )}
            {step === 3 && (
              <>
                <div className="container-page" style={{ marginBottom: '50px' }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <Typography.Title level={3} className="mt-3 fw-7">
                      Case information
                    </Typography.Title>
                  </div>
                  <CaseInfo mode="view" />
                  <ProductInfo onValidate={handleValidateProducts} form={form} mode="view" />
                  <Divider />
                  <TotalForm form={form} />
                </div>
              </>
            )}
          </Form>
          {step === 3 && (
            <Button
              htmlType="submit"
              onClick={handleClickSubmitBtn}
              className="fixed-bottom-btn"
              block
              type="primary"
              // loading={loading}
            >
              Submit
            </Button>
          )}
        </div>
      </Content>
    </>
  );
};

export default CreateNewCase;
