import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import React from 'react';

const DatePickerCustom = ({ value, onChange, formatValue, ...remain }) => {
  const newValue = value ? dayjs(value, 'YYYY.MM.DD') : value;
  const handleOnChange = (value) => {
    formatValue && value ? onChange(dayjs(value).format(formatValue)) : onChange(value);
  };
  return <DatePicker {...remain} className="w-100" value={newValue} onChange={handleOnChange} />;
};

export default DatePickerCustom;
