import { Button, Drawer } from 'antd';
import React from 'react';
import ActionIcon from '../../../../common/components/ActionIcons';
import { CloseOutlined } from '@ant-design/icons';
import { Text14, Text16 } from '../../../../common/components/text';

const MemoDrawer = ({ open, onClose, data }) => {
  return (
    <Drawer
      title={<Text16>Memo</Text16>}
      placement={'bottom'}
      closable={false}
      onClose={onClose}
      open={open}
      rootClassName="optiosDrawer"
      extra={<ActionIcon icon={<CloseOutlined />} onClick={onClose} />}
      contentWrapperStyle={{
        minHeight: '250px',
        maxHeight: '280px',
        overflow: 'scroll',
      }}>
      <div style={{
        height:'100px',
        overflow:'scroll'
      }}>
        <Text14
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word'
          }}>
          {data.note}
        </Text14>
      </div>
      <Button block className="mt-4" onClick={onClose}>
        Close
      </Button>
    </Drawer>
  );
};

export default MemoDrawer;
