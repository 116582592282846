import { useEffect, useState } from 'react';
import { Select } from 'antd';
import { truncateString } from '../../../utility/utils';

const AsyncSelect = ({ fetchApi, shouldFetch = true, ...props }) => {
  const { Option } = Select;
  const [data, setData] = useState([]);
  useEffect(() => {
    const request = async () => {
      const data = await fetchApi();
      setData(data);
    };
    if (shouldFetch) {
      request();
    }
  }, [fetchApi, shouldFetch]);

  return (
    <Select
      optionLabelProp="label"
      // options={data}
      {...props}
    >
    {data.map((option) => (
      <Option key={option.value} {...option}>
        <div>
          <div>{option.label}</div>
          {option?.description && (<div style={{
          width: '100%',
          whiteSpace: 'pre-wrap',
          textAlign: 'justify',
          overflowWrap: 'anywhere',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          maxHeight: '75px',
          fontSize: '12px',
        }}>{truncateString(option?.description)}</div>)}
        </div>
      </Option>
    ))}
    </Select>
  );
};

export default AsyncSelect;
