import { Button, Card, Image } from 'antd';
import React, { useState } from 'react';
import { ExternalLink, HeartFill, HeartOutLine } from '../../../asset/icons/Icons';
import { base64Image } from '../../../asset/images/ImageDefault';
import CopyButton from '../CopyButton';
import './style.scss';
import { Heart } from 'react-feather';
import ActionIcon from '../ActionIcons';
import { updateAddWish, updateRemoveWish } from '../../../views/features/request/api';
import { useDispatch, useSelector } from 'react-redux';
import { addWish, removeWish } from '../../../redux/reducers/requestSlice';

const ProductCard = ({ data, key, onOpenOption, searchData = {} }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const onClickWish = () => {
    if (data.in_wishlist) {
      dispatch(removeWish(data.id));
      updateRemoveWish(data.id);
    } else {
      dispatch(addWish(data.id));
      updateAddWish(data.id);
    }
  };
  return (
    <>
      <Card
        key={key}
        bordered={false}
        bodyStyle={{ display: 'flex', fontSize: '11px', position: 'relative' }}
        cover={
          <Image
            style={{
              height: '188px',
              borderRadius: '0',
              objectFit: 'cover'
            }}
            src={data?.product_thumbnail || base64Image}
          />
        }>
        <Card.Grid
          hoverable={false}
          style={{
            width: '64px',
            textAlign: 'center',
            padding: '0',
            backgroundColor: '#F2F4F7',
            boxShadow: 'none'
          }}>
          <p>Product</p>
          <p>Price</p>
          <p>Requested</p>
        </Card.Grid>
        <Card.Grid
          hoverable={false}
          style={{
            // width: 'auto',
            textAlign: 'center',
            padding: '0',
            flex: '1',
            boxShadow: 'none'
          }}>
          <CopyButton
            value={data?.id}
            text={!!data?.name_en?.trim() ? data?.name_en : data?.name_ko}
            textNoti={`Copied : Product ID (${data?.id})`}
          />
          <p>€{data?.price}</p>
          <div className="containerOption">
            <div className="requested">{data?.requested}</div>
            <Button
              type="primary"
              className="optionButton"
              block
              style={{
                border: '0',
                boxShadow: 'none',
                height: '35px'
              }}
              onClick={() => onOpenOption(data?.id)}>
              Option <ExternalLink />
            </Button>
          </div>
        </Card.Grid>
        {userInfo?.role !== 'md_employee' ? (
          <>
            <div className="commission-tag">
              <div>Commission</div>
              <div>
                <b>{data?.commission ? `€${data?.commission}` : 'TBD'}</b>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        <div className="heart-icon" onClick={onClickWish}>
          {data?.in_wishlist ? <HeartFill /> : <HeartOutLine />}
        </div>
      </Card>
    </>
  );
};

export default ProductCard;
