import { forEach } from 'lodash';
import apiClient, { apiClientV2 } from '../../../services/axios';

export const fetchCases = async (data, thunkApi) => {
  const params = new URLSearchParams();
  forEach(data, (value, key) => {
    if (value) {
      if (key !== 'categories') {
        params.append(key, value);
      } else {
        value.forEach((c) => {
          if (c) {
            params.append('categories', c);
          }
        });
      }
    }
  });
  try {
    const response = await apiClient.get('/case', {
      params
    });
    if (response.status === 200) {
      return response.data;
    }
    return thunkApi.rejectWithValue(response);
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
};

export const getProductDetail = async (id) => {
  const response = await apiClientV2.get(`/product/${id}`);
  return response.data;
};

export const getProductVariants = async (id) => {
  const response = await apiClientV2.get(`/product/${id}/variants`);
  return response.data;
};

export const getProductRequestByVariant = async (productId, varariantId) => {
  const response = await apiClient.get(`/product-request/${productId}/variants`, {
    params: { product_variant_id: varariantId }
  });
  return response.data.resources[0];
};

export const fetchCaseStatus = async (thunkApi) => {
  try {
    const response = await apiClient.get('/case/agg/status');
    if (response.status === 200) {
      return response.data;
    }
    return thunkApi.rejectWithValue(response);
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
};
