import { Switch } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useState } from 'react';
import HeaderMD2 from '../../../common/layout/HeaderMD2';

const NotificationSetting = () => {
  const [smsNoti, setSmsNoti] = useState(true);
  const handleOnchange = (value) => {
    setSmsNoti(value);
  };
  return (
    <>
      <HeaderMD2 title={'Notification Setting'} />
      <Content
        style={{
          minHeight: 380,
          backgroundColor: '#fff',
          paddingBottom: '10px'
        }}>
        <div className="container-page mt-4">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <div className="text-16">SMS notification</div>
              <div className="text-14">You can receive SMS notifications</div>
            </div>
            <Switch checked={smsNoti} onChange={handleOnchange}  />
          </div>
        </div>
      </Content>
    </>
  );
};

export default NotificationSetting;
