import { Button, Card, Image, Modal, Progress, Spin, Upload } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { UploadIcon } from '../../../asset/icons/Icons';
import { uploadFiles } from '../../api';
import './style.scss';

const UploadFileCustom = ({
  multiple = true,
  accept,
  maxCount = 5,
  value = [],
  onChange,
  isViewOnly = false,
  group = null,
  ...remain
}) => {
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [percentUpload, setPercentUpload] = useState(0);
  let groupVal = value;
  if (group) {
    groupVal = value.flatMap((v) => {
      return +v?.group === group ? v : [];
    });
  }
  const uploadImage = async (files) => {
    let arrayFile = [...files];
    const numCurFile = value?.length || 0;
    if (files?.length > 5 - numCurFile) {
      arrayFile = [...files].slice(0, 5 - numCurFile);
    }
    const payload = {
      feature_type: 'md_case',
      files: arrayFile
    };
    setUploading(true);
    const res = await uploadFiles(payload, (progressEvent) => {
      const { loaded, total } = progressEvent;
      let percent = Math.floor((loaded * 100) / total);
      setPercentUpload(percent);
      if (percent === 100) {
        setTimeout(() => {
          setUploading(false);
          setPercentUpload(0);
        }, 300);
      }
    });
    if (res?.data) {
      if (group) {
        res?.data?.map((d) => {
          d.group = group;
        });
      }
      onChange([...value, ...res.data]);
      groupVal = [...groupVal, ...res.data];
    }
    // setUploading(false);
  };

  useEffect(() => {
    // onChange(fileList)
    if (fileList?.length > 0) {
      uploadImage(fileList);
    }
  }, [fileList]);

  const props = {
    name: 'file',
    multiple,
    accept,
    maxCount,

    beforeUpload: (file, files) => {
      //  let newFileList = [...fileList, file]
      setFileList(files);

      return false;
    },
    showUploadList: false,
    // fileList,
    ...remain
  };
  const handleDelete = (file) => {
    const newFileList = [...value].filter((item) => item.file_name !== file.file_name);
    onChange(newFileList);
    // setFileList(newFileList);
  };

  return (
    <>
      {isViewOnly ? (
        <div className="list-file" style={{ border: 'none' }}>
          {groupVal.map((file, index) => (
            <div className="file-item" key={index}>
              <Image
                width={96}
                height={96}
                src={file?.file_view || 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'}
              />
              {!isViewOnly && (
                <Button className="mt-1" onClick={() => handleDelete(file)}>
                  Delete
                </Button>
              )}
            </div>
          ))}
        </div>
      ) : (
        <>
          {groupVal.length === 0 ? (
            <Upload {...props} className="upload-custom">
              <Card bodyStyle={{ width: '100%', padding: '10px 0 0 0' }}>
                <div className="d-flex align-items-center flex-column">
                  {/* <p className="ant-upload-drag-icon"> */}
                  <UploadIcon />

                  {/* </p> */}
                  <p className="ant-upload-text" style={{ color: '#6941C6' }}>
                    Click to upload (Up to {maxCount})
                  </p>
                  <p className="ant-upload-hint">PDF or JPG</p>
                </div>
              </Card>
            </Upload>
          ) : (
            <Card bodyStyle={{ width: '100%' }} className="container-upload">
              <Upload {...props} className="upload-custom">
                <Button block disabled={value.length >= maxCount} loading={uploading}>
                  Add Images (Up to {maxCount})
                </Button>
              </Upload>

              <div className="list-file">
                {' '}
                {groupVal.map((file, index) => (
                  <div className="file-item" key={index}>
                    <Image
                      width={96}
                      height={96}
                      src={
                        file?.file_view ||
                        'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
                      }
                    />

                    <Button className="mt-1" onClick={() => handleDelete(file)}>
                      Delete
                    </Button>
                  </div>
                ))}
              </div>
            </Card>
          )}
        </>
      )}
      <Modal
        className="progress-upload"
        centered
        open={uploading}
        footer={null}
        maskClosable={false}
        title={null}
        closable={false}>
        <Progress percent={percentUpload} size={300} status="active" strokeColor={{ from: '#10264D', to: '#10264D' }} />
      </Modal>
    </>
  );
};

export default UploadFileCustom;
