import { Button, Divider } from 'antd';
import React, { useEffect } from 'react';
import { Book, Mail, Camera } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ContactAdmin from '../../../../common/layout/ContactAdmin';
import { fetchUserInfoThunk, logoutThunk } from '../../../../redux/reducers/authSlice';
import { openContactAdmin } from '../../../../redux/reducers/layoutSlice';

const ThankYouPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate()
  const { userInfo, getInfoLoading, isLogin, loading } = useSelector((state) => state.auth);
  const full_name = userInfo?.full_name
  const dispatch = useDispatch();
  useEffect(() => {
    if(!isLogin){
      navigate('/login')
    }else{
      if(!userInfo?.id){
        dispatch(fetchUserInfoThunk())
      }
    }
  }, [isLogin]);
  return (
    <div className=" d-flex flex-column justify-content-between" style={{ height: '100vh', padding: '40px 24px' }}>
      <div>
        <div
          style={{
            fontSize: '32px',
            fontWeight: '400',
            color: '#101828'
          }}>
          Thank you!
        </div>
        <div
          style={{
            fontSize: '32px',
            fontWeight: '700',
            color: '#4BBF9F'
          }}
          className="mb-2">
          {full_name || ''}
        </div>
        <div>
          <p>{`You're registered`}</p>
          <p>It will take 1–3 business days for the examination</p>
          <p>We will contact you at the number you entered when the examination is completed</p>
        </div>
        <Button
          type="primary"
          block
          size="large"
          className="mt-3"
          loading={loading}
          onClick={() => {
            dispatch(logoutThunk());
          }}>
          Log out
        </Button>
      </div>
      <div>
        <Divider plain>If you have any questions</Divider>
        <div
          style={{
            padding: '0 24px'
          }}>
          <a
            href="https://bylocl.notion.site/bylocl/HAUTEVIE-LIMITED-LUXURY-MD-GUIDE-954b7e92cf864ec790effa79688ffbf8"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'none',
              color: '#101828'
            }}>
            <Button block size="large" className="d-flex" style={{ border: '1px solid #101828' }}>
              <div className="me-2">
                <Book />
              </div>
              <div>MD Guide & FAQ</div>
            </Button>
          </a>
          <Button
            block
            className="mt-3 d-flex"
            size="large"
            style={{ border: '1px solid #101828' }}
            onClick={() => dispatch(openContactAdmin())}>
            <div className="me-2">
              <Mail />
            </div>
            <div style={{ color: '#101828' }}>Contact to admin</div>
          </Button>
        </div>
      </div>
      <ContactAdmin />
    </div>
  );
};

export default ThankYouPage;
