import { Button, Form, Input, Layout, message } from 'antd';
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import UploadFileCustom from '../../../common/components/UploadFile';
import HeaderMD2 from '../../../common/layout/HeaderMD2';
import apiClient from '../../../services/axios';
import './style.scss';
const { Content } = Layout;

const RefundInvoice = () => {
  const [searchParams] = useSearchParams();
  const caseId = searchParams.get('id');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = async (value) => {
    setLoading(true);
    try {
      const { data } = await apiClient.post(`/case/product/${caseId}/refund`, {
        ...value
      });
      message.success('Successfully');
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    } catch (error) {
      message.error(error?.response?.data?.message || 'Error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <HeaderMD2 title={'Refund invoice'} />
      <Content
        style={{
          minHeight: 380,
          backgroundColor: '#fff',
          paddingBottom: '10px'
        }}>
        <Form name="refund-invoice" onFinish={handleSubmit}>
          <div className="container-page" style={{ paddingTop: '8px' }}>
            <Form.Item name="pictures" label='Invoice image' required>
              <UploadFileCustom />
            </Form.Item>
            <Form.Item name="note" label='Memo'>
              <Input.TextArea rows={4} />
            </Form.Item>
          </div>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              return (
                <Button
                  loading={loading}
                  block
                  className="submit-btn"
                  type="primary"
                  htmlType="submit"
                  disabled={!getFieldValue('pictures')?.length}>
                  Submit
                </Button>
              );
            }}
          </Form.Item>
        </Form>
      </Content>
    </>
  );
};

export default RefundInvoice;
