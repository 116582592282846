import { Avatar, Divider, List, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RightOutlined } from '../../../asset/icons/Icons';
import ActionIcon from '../../../common/components/ActionIcons';
import { Text12, Text14, Text16, Text20 } from '../../../common/components/text';
import HeaderMD from '../../../common/layout/HeaderMD';
import { fetchCommissionHistoryThunk, fetchCommissionTotalThunk } from '../../../redux/reducers/commissionSlice';
import MemoDrawer from '../../features/commission-history/components/MemoDrawer';

const CommissionHistoryPage = () => {
  const dispatch = useDispatch();
  const [openMemoDrawer, setOpenMemoDrawer] = useState(false);
  const [curCommission, setCurCommission] = useState({});
  const { resources, loading, totalCommission, remainingCommission } = useSelector((state) => state.commission);

  useEffect(() => {
    dispatch(fetchCommissionHistoryThunk({
      sort:'-created_at'
    }));
    dispatch(fetchCommissionTotalThunk());
  }, []);

  return (
    <>
      <HeaderMD title={'Commission'} />
      <Content className="container-page">
        <div className="fw-4 gray-700 mt-3 mb-1" style={{ fontSize: '16px' }}>
          Remaining commission
        </div>
        <Text20>€{remainingCommission || 0}</Text20>
        <Divider style={{ marginBottom: '20px', marginTop: '16px' }} />
        <div className="d-flex justify-content-between">
          <Text14>Total commission</Text14>
          <Text16>€{totalCommission || 0}</Text16>
        </div>
        <Divider style={{ marginBottom: '0' }} />
        <List
          dataSource={resources}
          loading={loading}
          renderItem={(item) => (
            <List.Item key={item.description} style={{ height: '78px', justifyContent: 'unset' }}>
              <div style={{ minWidth: '50px' }}>
                <Text14 className="text-center">
                  {item?.type === 'payment' ? (item?.md_case_id ? 'Buy' : '') : 'Paid'}
                </Text14>
                <Text16 style={{ color: item?.type === 'payment' ? '#4BBF9F' : '#F63D68' }}>
                  {item?.type === 'payment' ? '+' : '-'}€{Math.abs(item?.value)}
                </Text16>
              </div>
              <Divider type="vertical" className="h-75" />
              <div className="d-flex justify-content-between align-items-center flex-fill">
                <div>
                  <Text12>{dayjs(item?.created_at).format('YYYY.MM.DD')}</Text12>
                  {item?.md_case_id ? (
                    <Typography.Text>
                      <Text12
                        style={{
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          width: '190px'
                        }}>
                        {item?.product?.name_en?.trim() ? item?.product?.name_en : item?.product?.name_ko}
                      </Text12>
                    </Typography.Text>
                  ) : (
                    <Typography.Text>
                      <Text12
                        style={{
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          width: '190px'
                        }}>
                        {item.note}
                      </Text12>
                    </Typography.Text>
                  )}
                </div>
                {item.note && (
                  <ActionIcon
                    icon={<RightOutlined />}
                    onClick={() => {
                      setOpenMemoDrawer(true);
                      setCurCommission(item);
                    }}
                  />
                )}
              </div>
            </List.Item>
          )}
        />
        <Divider style={{ marginTop: '0' }} />
      </Content>
      <MemoDrawer open={openMemoDrawer} onClose={() => setOpenMemoDrawer(false)} data={curCommission} />
    </>
  );
};

export default CommissionHistoryPage;
