import { Button, Col, Form, Grid, Input, Layout, message, Row, Select, Space, Spin, Statistic } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import HeaderMD2 from '../../../../common/layout/HeaderMD2';
import { apiClientV2 } from '../../../../services/axios';
import { useDispatch, useSelector } from 'react-redux';
import { logoutThunk } from '../../../../redux/reducers/authSlice';

const OPTIONS_PHONE = [
  {
    value: 'korean',
    label: 'Korea +82'
  }
];

const CertifyPhone = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isCountDown, setIsCountDown] = useState(false);
  const [countDownTime, setCountDownTime] = useState(null);
  const [certified, setCertified] = useState(false);
  const [otpCertified, setOtpCertified] = useState(false);
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [loadingCetify, setLoadingCetify] = useState(false);

  const { loading, isLogin } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!isLogin) {
      navigate('/login');
    }
  }, [isLogin]);

  const [form] = Form.useForm();

  const MOBILE_TYPE_OPTIONS = [
    {
      label: 'Korean +82',
      value: 'korean',
      code: '+82'
    },
    {
      label: 'France +33',
      value: 'france',
      code: '+33'
    }
    // {
    //   label: 'VietNam +84',
    //   value: 'vietnam',
    //   code: '+84'
    // }
  ];

  const authentication = async (body) => {
    setLoadingAuth(true);
    try {
      const { data } = await apiClientV2.put('/user/me', body);
      setLoadingAuth(false);
      // navigate('/require-step');
      navigate('/thank-you');
    } catch (error) {
      setLoadingAuth(false);
      message.error(error.response.data?.message || 'Sign up phone number failed');
    }
  };

  const certify = async (body) => {
    setLoadingCetify(true);
    try {
      const res = await apiClientV2.post('/user/me/phone/certify', body);
      setLoadingCetify(false);
      setIsCountDown(true);
      setCertified(true);
      setCountDownTime(Date.now() + 180 * 1000);
    } catch (error) {
      setLoadingCetify(false);
      message.error(error.response.data?.message || 'Certify Failed');
    }
  };

  const handleCertified = () => {
    const { phone, areaCode } = form.getFieldsValue();
    certify({
      phone: getPhoneNumber(areaCode, phone)
    });
  };

  const handleAuthentication = () => {
    const { phone, areaCode, otp } = form.getFieldsValue();
    const body = {
      phone: getPhoneNumber(areaCode, phone),
      otp
    };
    authentication(body);
  };

  const getPhoneNumber = (code, phone) => {
    if (!phone?.includes('+')) {
      const codeValue = MOBILE_TYPE_OPTIONS.filter((item) => item.value === code)?.[0]?.code;
      let subPhone = phone;
      if (subPhone.charAt(0) === '0') {
        subPhone = phone.slice(1, phone.length);
      }
      return codeValue + subPhone;
    } else {
      return phone;
    }
  };

  const handleTimeUp = ()=>{
    setCertified(false)
    setIsCountDown(false)
    setCountDownTime(null)
  }

  return (
    <Layout
      style={{
        backgroundColor: '#fff'
      }}>
      <Content
        style={{
          backgroundColor: '#fff',
          paddingBottom: '10px',
          paddingTop: '40px',
          height: '100vh'
        }}>
        <div className="container-page d-flex align-items-end flex-column" style={{ height: '100%' }}>
          <div className="w-100">
            <div className="text-20 fw-400 mb-3">Need a phone number to identify yourself</div>
            <Form
              form={form}
              name="profile"
              layout="vertical"
              // onFinish={handleFinish}
              initialValues={{
                areaCode: 'korean'
              }}>
              <Spin spinning={false} style={{ height: '100%' }}>
                <Form.Item name="areaCode" label={<b className="gray-700">Phone Number</b>}>
                  <Select size="large">
                    <Select.Option value="korean" label="+82 Korean">
                      <Space>+82 Korean</Space>
                    </Select.Option>
                    <Select.Option value="france" label="+33 France">
                      <Space>+33 France</Space>
                    </Select.Option>
                  </Select>
                </Form.Item>
                <div style={{ width: '100%' }} className="d-flex justify-content-between">
                  <Form.Item name="phone" className="w-100">
                    <Input size="large" placeholder="Enter phone number" className="w-100" />
                  </Form.Item>
                  <Form.Item shouldUpdate={(preValue, curValue) => preValue.phone !== curValue.phone}>
                    {({ getFieldValue }) => {
                      return (
                        <Button
                          size="large"
                          type="primary"
                          className="green-btn ms-2"
                          onClick={handleCertified}
                          loading={loadingCetify}
                          disabled={!getFieldValue('phone')}>
                          {isCountDown ? 'Resend' : 'Certified'}
                        </Button>
                      );
                    }}
                  </Form.Item>
                </div>
                {isCountDown && (
                  <>
                    <Form.Item name="otp">
                      <Input
                        size="large"
                        placeholder="Enter certified number"
                        suffix={
                          isCountDown && (
                            <Statistic.Countdown
                              valueStyle={{
                                fontSize: '16px',
                                color: '#4BBF9F'
                              }}
                              format="mm:ss"
                              value={countDownTime}
                              onFinish={handleTimeUp}
                            />
                          )
                        }
                      />
                    </Form.Item>
                    <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.otp !== curValues.otp}>
                      {({ getFieldValue }) => {
                        if (getFieldValue('otp')?.length === 6) {
                          setOtpCertified(true);
                        } else {
                          setOtpCertified(false);
                        }
                        return (
                          <Button
                            loading={loadingAuth}
                            type="primary"
                            block
                            size="large"
                            className="green-btn mb-2"
                            onClick={handleAuthentication}
                            disabled={!(otpCertified && certified)}>
                            Continue
                          </Button>
                        );
                      }}
                    </Form.Item>
                  </>
                )}
                <Button block type="primary" size="large" className="mt-2" onClick={() => dispatch(logoutThunk())}>
                  Log out
                </Button>
              </Spin>
            </Form>
          </div>
          <div className="mt-auto text-14 gray-700 w-100">
            By continuing, you are setting up a Hautevie account and agree to our <Link>User Agreement</Link> and
            <Link> Privacy Policy</Link>.
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default CertifyPhone;
