import { Typography, message } from 'antd';
import React from 'react';
import { CopyIcon, BigCopyIcon } from '../../../asset/icons/Icons';

const CopyButton = ({ value, text, textNoti, isBig = false }) => {
  return (
    <Typography.Paragraph
      ellipsis={{ tooltip: text }}
      copyable={{
        text: value,
        tooltips: false,
        icon: isBig ? <BigCopyIcon /> : <CopyIcon />,
        onCopy: () => {
          textNoti && message.success(textNoti);
        }
      }}
      style={{ marginBottom: '0', width: '100%' }}>
      {text}
    </Typography.Paragraph>
  );
};

export default CopyButton;
