import { Col, Input, Form, Row, Select, Checkbox } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React from 'react';
import AsyncSelect from '../../../../common/components/AsyncSelect/AsyncSelect';
import DatePickerCustom from '../../../../common/components/DatePicker';
import { Text12, Text14 } from '../../../../common/components/text';
import UploadFileCustom from '../../../../common/components/UploadFile';
import { DELIVERY_LOCATION } from '../../../../common/utilities/constants';
import { fetchSupplierList } from '../../common/api';
import BrandAsyncSelect from '../../common/components/BrandAsyncSelect';
import CurrencyAsyncSelect from '../../common/components/CurrencyAsyncSelect';

const CaseInfo = ({ mode = 'edit', form }) => {
  const fetchSuppliersSelect = async (brand_id) => {
    const data = await fetchSupplierList({
      page: 1,
      size: 500,
      brand_id: +brand_id || undefined
    }).then(({ resources = [] }) =>
      resources
        .map((r) => ({
          value: r.id,
          label: r.name ? r.name : r.id?.toString(),
          country: r.country,
          description: r.note ? r.country + '/' + r.note : r.country
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    );
    return data;
  };

  return (
    <div>
      <Form.Item label="Brand" name="supplier_brand_id" required={mode === 'edit'}>
        <BrandAsyncSelect
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
        />
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(preValue, curValue) => preValue.supplier_brand_id !== curValue.supplier_brand_id}>
        {({ getFieldValue }) => {
          return (
            <Form.Item label="Supplier" name="supplier_id" required={mode === 'edit'}>
              <AsyncSelect
                showSearch
                optionFilterProp="children"
                placeholder="Supplier Name"
                fetchApi={() => fetchSuppliersSelect(getFieldValue('supplier_brand_id'))}
                allowClear
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                onChange={(_, option) => {
                  form.setFieldValue('country', option.country);
                }}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
      <Form.Item label="Supplier invoice date" name="invoice_date" required={mode === 'edit'}>
        <DatePickerCustom className="w-100" format={'YYYY.MM.DD'} />
      </Form.Item>
      <Form.Item label="Supplier Country" name="country">
        <Select options={DELIVERY_LOCATION} placeholder="Country" disabled />
      </Form.Item>
      <Row gutter={[10]}>
        <Col span={12}>
          <Form.Item label="Delivery Location" name="delivery_location" required={mode === 'edit'}>
            <Select
              options={DELIVERY_LOCATION}
              placeholder="Delivery Location"
              onChange={(value) => {
                if (value === 'france') {
                  form.setFieldValue('currency_id', 3);
                }
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Currency" name="currency_id" required={mode === 'edit'}>
            <CurrencyAsyncSelect />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label="Case invoice images" name="pictures" required={mode === 'edit'}>
        <UploadFileCustom isViewOnly={mode === 'view' ? true : false} />
      </Form.Item>
      <Form.Item label="Shipping Company" name="shipping_company">
        <Input placeholder="Shipping Company" />
      </Form.Item>
      <Form.Item label="Tracking Number" name="tracking_number">
        <Input placeholder="Tracking Number" />
      </Form.Item>
      <Form.Item label="Order Online" shouldUpdate>
        {({ getFieldValue }) => {
          const val = getFieldValue('by_online');
          return (
            <div className="d-flex justify-content-between">
                <div>
                  <Text14
                    style={{
                      color: 'rgba(0, 0, 0, 0.88)'
                    }}>
                    This product was purchased online
                  </Text14>
                  <Text12>If you purchased online, please check</Text12>
                </div>
                <Form.Item name="by_online">
                <Checkbox
                  checked={val}
                  onChange={(e) => {
                    form.setFieldValue('by_online', e.target.checked);
                  }}
                />
                </Form.Item>
              </div>
          );
        }}
      </Form.Item>
      <Form.Item label="Memo" name="memo">
        <TextArea rows={3} placeholder="Take a Note" />
      </Form.Item>
    </div>
  );
};

export default CaseInfo;
