import { DoubleLeftOutlined } from '@ant-design/icons';
import { Button, Card, Divider, Drawer, Layout, Menu, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { MenuBar, UserOutline } from '../../asset/icons/Icons';
import LogoLogin from '../../asset/images/LogoLogin.svg';
import { fetchUserInfoThunk, logoutThunk, setUserInfo } from '../../redux/reducers/authSlice';
import { closeContactAdmin, closeSideBar, openContactAdmin } from '../../redux/reducers/layoutSlice';
import ActionIcon from '../components/ActionIcons/index';
import './style.scss';
import NoticeButton from '../components/NoticeButton';
import { profile } from '../../services/authService';
import { Book, Mail, Camera, LogOut, X } from 'react-feather';
import ContactAdmin from './ContactAdmin';

const HTVLayout = () => {
  const [selectedKey, setSelectedKey] = useState('request');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  const { isOpenSideBar, isOpenContactAdmin } = useSelector((state) => state.layout);
  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    if (location?.pathname && location?.pathname !== selectedKey) {
      setSelectedKey(location?.pathname?.split('/')?.[1]);
    }
    dispatch(fetchUserInfoThunk());
  }, [dispatch]);

  const navItems = [
    {
      label: 'Request',
      key: 'request'
    },
    {
      label: 'Case',
      key: 'case'
    },
    {
      label: 'Commission history',
      key: 'commission-history'
    },
    {
      label: 'Wishlist',
      key: 'wish-list'
    },
    {
      label: (
        <a
          href="https://bylocl.notion.site/bylocl/HAUTEVIE-LIMITED-LUXURY-MD-GUIDE-954b7e92cf864ec790effa79688ffbf8"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: 'none'
          }}>
          <p style={{ color: 'rgba(0, 0, 0, 0.88)' }}>MD Guide & FAQ</p>
        </a>
      ),
      key: 'link',
      disabled: true
    }
  ];
  const handleOnlClickNav = (e) => {
    if (e.key !== 'link') {
      const path = e.keyPath?.[0];
      setSelectedKey(e.key);
      navigate(path);
    }
    dispatch(closeSideBar());
  };

  const handleClickLogout = () => {
    dispatch(logoutThunk());
  };

  return (
    <>
      <Layout
        style={{
          backgroundColor: '#fff',
          paddingBottom: '10px'
        }}>
        {/* <Header
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 50,
            width: '100%',
            backgroundColor: '#ffff',
            display: 'flex',
            padding: '0 5vw 0 5vw',
            justifyContent: 'space-between',
            boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.15)'
          }}>
          <div className="d-flex">
            <div className="me-1">
              <ActionIcon icon={<MenuBar />} onClick={() => setOpenNavBar(true)} />
            </div>
            <p className="title">{getTitleHeader(selectedKey)}</p>
          </div>
        </Header> */}
        <Outlet />
        {/* <Content
          style={{
            minHeight: 380,
            backgroundColor: '#fff',
            paddingBottom: '10px'
          }}>
          
        </Content> */}
      </Layout>
      <Drawer
        title={
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <img style={{ height: '22px' }} src={LogoLogin} />
              </div>
              <Button onClick={() => dispatch(closeSideBar())}>
                <DoubleLeftOutlined />
              </Button>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-4">
              <div>
                <div style={{ fontSize: '14px', color: '#101828' }}>
                  <b>Hello, {userInfo?.full_name}</b>
                </div>
                <div style={{ fontSize: '12px', color: '#667085' }}>{userInfo?.username}</div>
              </div>
              <div>
                <Button
                  icon={<UserOutline />}
                  onClick={() => {
                    navigate('/profile');
                    dispatch(closeSideBar());
                  }}
                />
              </div>
            </div>
          </div>
        }
        placement="left"
        onClose={() => dispatch(closeSideBar())}
        open={isOpenSideBar}
        contentWrapperStyle={{
          width: '280px'
        }}
        closable={false}
        // extra={
        //   <Space>
        //     <Button onClick={() => dispatch(closeSideBar())}>
        //       <DoubleLeftOutlined />
        //     </Button>
        //   </Space>
        // }
      >
        <Menu
          mode="inline"
          defaultSelectedKeys={selectedKey}
          // defaultOpenKeys={['request']}
          style={{ height: '80%', borderRight: 0 }}
          items={navItems}
          onClick={handleOnlClickNav}
        />
        <Button
          size="large"
          onClick={handleClickLogout}
          block
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '16px'
          }}>
          <div>Logout</div>
          <div>
            <LogOut />
          </div>
        </Button>
        <Button
          size="large"
          onClick={() => {
            dispatch(closeSideBar());
            setTimeout(() => {
              dispatch(openContactAdmin());
            }, 500);
          }}
          block
          className="d-flex">
          <div className="me-1">
            <Mail />
          </div>
          Contact to admin
        </Button>
      </Drawer>
      <NoticeButton pathname={location?.pathname} />
      <ContactAdmin />
    </>
  );
};
export default HTVLayout;
