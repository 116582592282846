import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchNotices } from '../../views/features/notification/api';

export const fetchNoticesThunk = createAsyncThunk('md/notification/all', async (thunkApi) => {
  return await fetchNotices(thunkApi);
});

export const fetchNumberOfNoticesThunk = createAsyncThunk('md/number/notification/all', async (thunkApi) => {
  return await fetchNotices(thunkApi);
});

const initialState = {
  loading: false,
  resources: [],
  unRead: null,
};

export const notificationSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    markRead: (state, action) => {
      state.resources = state.resources.map((item) => {
        if (item.id === action.payload) {
          return {
            ...item,
            read_at: true
          };
        } else return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNoticesThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNoticesThunk.fulfilled, (state, action) => {
        state.resources = action.payload.resources;
        state.unRead = action.payload.resources.filter((item) => !item.read_at).length || null;
        state.loading = false;
      })
      .addCase(fetchNoticesThunk.rejected, (state, action) => {
        state.loading = false;
        state.notices = [];
      })
      .addCase(fetchNumberOfNoticesThunk.fulfilled, (state, action) => {
        const newUnRead = action.payload.resources.filter((item) => !item.read_at).length;
        if (state.unRead !== newUnRead) {
          state.unRead = newUnRead;
        }
      });
  }
});

export const { markRead } = notificationSlice.actions;

export default notificationSlice.reducer;
