import React from 'react';

const Text16 = ({ children, style }) => {
  return (
    <div
      style={{
        fontSize:'16px',
        fontWeight:'700',
        color:'#344054',
        ...style
      }}>
      {children}
    </div>
  );
};

export default Text16;
