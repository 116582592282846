import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, message, Modal, Row, Select, Space, Spin } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { set } from 'lodash';
import React, { useState } from 'react';
import InputNumberCustom from '../../../../common/components/ActionIcons/InputNumber/InputNumberCustom';
import UploadFileCustom from '../../../../common/components/UploadFile';
import { DEFAULT_QUANTITY_DROPDOWN, STATUS_OF_PRODUCT } from '../../../../common/utilities/constants';
import { getProductDetail, getProductVariants, getProductRequestByVariant } from '../api';
import RequestSearchModal from './RequestSearchModal';

const ProductInfo = ({ mode = 'edit', onValidate, form }) => {
  const [isOpenSearchRequest, setIsOpenSearchRequest] = useState(false);
  const [loadings, setLoadings] = useState([]);
  const [options, setOptions] = useState([]);
  const [quantity, setQuantity] = useState([]);
  const [productKey, setProductKey] = useState('0');

  const setProductLoading = (key, isLoading) => {
    setLoadings((state) => {
      const newState = [...state];
      newState[key] = isLoading;
      return newState;
    });
  };

  const handleRemove = (remove) => {
    Modal.confirm({
      title: 'Are you sure you want to delete it?',
      onCancel: () => {},
      onOk: () => {
        remove();
      },
      centered: true,
      icon: null,
      cancelText: 'Close',
      okText: 'Delete',
      okButtonProps: {
        danger: true,
        type: 'primary',
        style: {
          marginRight: '8%',
          width: '40%'
        }
      },
      cancelButtonProps: {
        style: {
          width: '40%'
        }
      }
    });
  };

  const onAutoFill = async (key, productId) => {
    setIsOpenSearchRequest(false);
    await handleSearchId(key, productId);
  };

  const handleSearchId = async (key, initProductId = null) => {
    setProductLoading(key, true);
    let productId = initProductId?.toString() || form.getFieldValue('products')?.[key]?.product_id.toString();
    let variantId;

    if (productId?.includes('-')) {
      const tmpList = productId.split('-');
      productId = parseInt(tmpList?.[0]?.trim());
      variantId = parseInt(tmpList?.[1]?.trim());
    }
    try {
      const product = await getProductDetail(productId);
      const variants = await getProductVariants(productId);
      const variant = variants.length === 1 ? variants[0] : variantId ? variants.find((v) => v.id === variantId) : null;
      const inProdReq = variant ? !!(await getProductRequestByVariant(product.id, variant.id)) : false;

      const products = form.getFieldValue('products');
      Object.assign(products[key], {
        product_id: product.id,
        product_name: product.name_en?.trim() || product.name_ko,
        product_variant_id: variant?.id,
        price: variant?.recent_gross_price || product.price,
        in_prod_request: inProdReq
        // vat_percent: 20,
        // discount: 0,
      });
      form.setFieldsValue({ products });

      const newOptions = [...options];
      newOptions[key] =
        variants.map((v) => ({
          value: v.id,
          label: `${product.id}-${v.id} (${v.properties?.map((p) => p.value).join(', ') || 'No variants'})`,
          product_id: product.id,
          recent_price: v.recent_gross_price
        })) || [];
      setOptions(newOptions);
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      setTimeout(() => {
        setProductLoading(key, false);
      }, 1000);
    }
  };

  const disableDeleteButton = () => {
    const products = form.getFieldValue('products') || [];
    return products.length <= 1;
  };

  const handleOnBlurDiscount = (e, key) => {
    const products = form.getFieldValue('products');
    if (!(products[key].gross_price > products[key].discount) && products[key].discount) {
      Modal.warning({
        title: <p style={{ fontweight: '700', color: '#101828' }}>Warning</p>,
        content: (
          <span style={{ fontSize: '14px', color: '#344054' }}>
            <b>Discount</b> must be less than the <b>Gross Price</b>
          </span>
        ),
        onOk: () => {
          form.setFieldsValue(set({ ...form.getFieldsValue() }, `products[${key}].discount`, 0));
        },
        icon: null,
        okText: 'Close',
        okButtonProps: {
          type: 'default',
          style: {
            width: '100%'
          }
        }
      });
    }
  };

  const getStatus = (value) => {
    const config = STATUS_OF_PRODUCT.filter((i) => i.value === value)?.[0];
    return config;
  };

  const handleOnSelectOption = async (key, option) => {
    const products = form.getFieldValue('products');
    if (option.recent_price) {
      products[key].price = option.recent_price;
    }
    products[key].in_prod_request = !!(await getProductRequestByVariant(option.product_id, option.value));
    form.setFieldsValue({ products });
  };

  const handleOnChangeQuantity = (e, key) => {
    const products = form.getFieldValue('products');
    setQuantity((quantity) => {
      const newQuantity = [...quantity];
      newQuantity[key] = products[key]?.quantity;
      return newQuantity;
    });
    _.remove(products[key]?.pictures, (p) => p?.group > products[key]?.quantity);
  };

  const showProductImages = (field) => {
    const products = form.getFieldValue('products');
    const qty = products[field.name]?.quantity || 1;
    const images = [];
    for (let i = 1; i <= qty; i++) {
      images.push(
        <Form.Item
          {...field}
          label={`Update Product images ${i}`}
          name={[field.name, 'pictures']}
          required={mode === 'edit'}>
          <UploadFileCustom group={i} accept="image/jpg" isViewOnly={mode === 'view' ? true : false} />
        </Form.Item>
      );
    }
    return <>{images}</>;
  };

  return (
    <>
      <Form.List name="products" noStyle>
        {(fields, { add, remove }) => (
          <div>
            {fields.map((field) => (
              <Space className='product-info-space' key={field.key}>
                <Spin tip="Loading..." spinning={loadings[field.key] || false}>
                  <Card className="mb-3">
                    <div className="d-flex justify-content-between align-item-center">
                      <div style={{ fontSize: '20px', color: '#344054', fontWeight: '700' }}>
                        Product.{field.name + 1}
                      </div>
                      {!(mode === 'view') && (
                        <Button
                          style={{ width: '120px' }}
                          icon={<DeleteOutlined style={{ fontSize: '20px' }} />}
                          size="large"
                          onClick={() => handleRemove(() => remove(field.name))}
                          disabled={disableDeleteButton()}
                          block>
                          Delete
                        </Button>
                      )}
                    </div>
                    <Form.Item
                      noStyle
                      // {...field}
                      shouldUpdate={true}
                      required={mode === 'edit'}>
                      {({ getFieldValue }) => {
                        const value = getStatus(getFieldValue('products')?.[field.name]?.status);
                        return (
                          getFieldValue('products')?.[field.name]?.status && (
                            <Form.Item label="Status">
                              <Input
                                disabled
                                value={value?.label}
                                style={{
                                  background: `${value?.color}`,
                                  color: '#FFFFFF'
                                }}
                              />
                            </Form.Item>
                          )
                        );
                      }}
                    </Form.Item>
                    <div className="d-flex justify-content-between align-items-end mb-2">
                      <Form.Item
                        {...field}
                        label="Product ID"
                        name={[field.name, 'product_id']}
                        style={{
                          flex: '1',
                          marginBottom: '0'
                        }}
                        required={mode === 'edit'}>
                        <Input disabled={mode === 'view'} className="w-100" />
                      </Form.Item>
                      <Button
                        icon={<SearchOutlined />}
                        style={{ marginLeft: '10px' }}
                        onClick={() => handleSearchId(field.key)}
                      />
                    </div>
                    <Button
                      block
                      type="primary"
                      style={{ marginBottom: '16px', boxShadow: 'unset' }}
                      onClick={() => {
                        setIsOpenSearchRequest(true);
                        setProductKey(field.name);
                      }}>
                      Search requested
                    </Button>
                    <Form.Item label="Product Name" name={[field.name, 'product_name']}>
                      <Input disabled />
                    </Form.Item>
                    <Form.Item
                      noStyle
                      // {...field}
                      shouldUpdate={(preValue, value) =>
                        preValue.products?.[field.name]?.product_id !== value.products?.[field.name]?.product_id
                      }>
                      {({ getFieldValue }) => {
                        return (
                          <Row gutter={[10]}>
                            <Col span={16}>
                              <Form.Item
                                {...field}
                                label="Option"
                                name={[field.name, 'product_variant_id']}
                                required={mode === 'edit'}>
                                <Select
                                  placeholder="Option"
                                  dropdownMatchSelectWidth={false}
                                  options={options[field.name]}
                                  disabled={!getFieldValue('products')[field.name]?.product_id || mode === 'view'}
                                  onSelect={(_, option) => handleOnSelectOption(field.name, option)}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item
                                {...field}
                                label="Qty"
                                name={[field.name, 'quantity']}
                                required={mode === 'edit'}>
                                <Select
                                  placeholder="Qty"
                                  options={DEFAULT_QUANTITY_DROPDOWN}
                                  disabled={!getFieldValue('products')[field.name]?.product_id || mode === 'view'}
                                  onChange={(e) => handleOnChangeQuantity(e, field.name)}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        );
                      }}
                    </Form.Item>
                    <Form.Item label="Recent Price (Gross)" name={[field.name, 'price']}>
                      <InputNumberCustom
                        formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/€\s?|(,*)/g, '')}
                        className="w-100"
                        disabled
                      />
                    </Form.Item>
                    <Form.Item
                      noStyle
                      shouldUpdate={(preValue, value) =>
                        preValue.products?.[field.name]?.product_id !== value.products?.[field.name]?.product_id
                      }>
                      {() => {
                        return (
                          <Row gutter={[10]}>
                            <Col span={8}>
                              <Form.Item
                                {...field}
                                label="Gross Price"
                                name={[field.name, 'gross_price']}
                                required={mode === 'edit'}>
                                <InputNumberCustom
                                  formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                  parser={(value) => value.replace(/€ \s?|(,*)/g, '')}
                                  className="w-100"
                                  disabled={!form.getFieldValue('products')[field.name]?.product_id || mode === 'view'}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item
                                {...field}
                                label="Discount"
                                name={[field.name, 'discount']}
                                required={mode === 'edit'}>
                                <InputNumberCustom
                                  formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                  parser={(value) => value.replace(/€ \s?|(,*)/g, '')}
                                  className="w-100"
                                  disabled={!form.getFieldValue('products')[field.name]?.product_id || mode === 'view'}
                                  onBlur={(e) => handleOnBlurDiscount(e, field.name)}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item
                                {...field}
                                label="Tax/Vat"
                                name={[field.name, 'vat_percent']}
                                required={mode === 'edit'}>
                                <Select
                                  placeholder="Tax/Vat"
                                  options={[
                                    {
                                      value: 0,
                                      label: '0%'
                                    },
                                    {
                                      value: 20,
                                      label: '20%'
                                    }
                                  ]}
                                  disabled={!form.getFieldValue('products')[field.name]?.product_id || mode === 'view'}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        );
                      }}
                    </Form.Item>
                    <Form.Item label="Total">
                      <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) => {
                          const product = getFieldValue('products')?.[field.name];
                          const quantity = product?.quantity || 0;
                          const discount = product?.discount || 0;
                          const gross_price = product?.gross_price || 0;
                          const total = parseFloat(quantity * (gross_price - discount) || 0);
                          return (
                            <InputNumberCustom
                              formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              parser={(value) => value.replace(/€\s?|(,*)/g, '')}
                              className="w-100"
                              value={total}
                              disabled
                            />
                          );
                        }}
                      </Form.Item>
                    </Form.Item>
                    {showProductImages(field)}
                    <Form.Item {...field} label="Memo" name={[field.name, 'memo']}>
                      <TextArea rows={3} placeholder="Take a Note" />
                    </Form.Item>
                  </Card>
                </Spin>
              </Space>
            ))}
            {!(mode === 'view') && (
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  return (
                    <Button
                      block
                      className="ms-1"
                      type="primary"
                      onClick={() =>
                        add({
                          product_id: null,
                          product_variant_id: null,
                          quantity: null,
                          gross_price: null,
                          discount: 0,
                          vat_percent: 20,
                          pictures: []
                        })
                      }
                      disabled={!onValidate(getFieldValue('products'))}>
                      Add another Product
                    </Button>
                  );
                }}
              </Form.Item>
            )}
          </div>
        )}
      </Form.List>
      <RequestSearchModal
        searchData={{ brand_id: form.getFieldValue('supplier_brand_id') }}
        isOpen={isOpenSearchRequest}
        onAutoFill={onAutoFill}
        productKey={productKey}
        onCancel={() => setIsOpenSearchRequest(false)}
      />
    </>
  );
};

export default ProductInfo;
