import { Button, Form, Input, Layout, message } from 'antd';
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import UploadFileCustom from '../../../common/components/UploadFile';
import HeaderMD2 from '../../../common/layout/HeaderMD2';
import apiClient from '../../../services/axios';
const { Content } = Layout;
import './style.scss';

const ReplacementRequest = () => {
  const [searchParams] = useSearchParams();
  const caseId = searchParams.get('id');
  const replacement_pics_group = searchParams.get('replacement_pics_group') === '' || searchParams.get('replacement_pics_group') === 'null' ? [] : searchParams.get('replacement_pics_group')?.split(',');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = async (values) => {
    setLoading(true);
    let updates = [];
    let invoices = [];
    if (values?.updates) {
      updates = values?.updates?.map((item) => ({
        ...item,
        picture_type: 'product'
      }));
    }
    if (values?.invoices) {
      invoices = values?.invoices?.map((item) => ({
        ...item,
        picture_type: 'invoice'
      }));
    }

    const body = {
      note: values.note,
      pictures: [...invoices, ...updates]
    };
    try {
      const { data } = await apiClient.post(`/case/product/${caseId}/replacement`, body);
      message.success('Successfully');
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    } catch (error) {
      message.error(error?.response?.data?.message || 'Error');
    } finally {
      setLoading(false);
    }
  };

  const handleValidate = (values) => {
    if (!values('invoices')?.length || !values('updates')?.length) {
      return false;
    }
    let validated = true;
    replacement_pics_group.map(g => {
      if (!values('updates')?.filter(u => u.group === +g)?.length) {
        validated = false;
      }
    });
    return validated;
  };

  return (
    <>
      <Content>
        <HeaderMD2 title={'Replacement invoice'} />
        <Form name="replacementRequest" onFinish={handleSubmit}>
          <div className="container-page" style={{ paddingTop: '8px', marginBottom: '50px' }}>
            <Form.Item name="invoices" label="Invoice image" required={true}>
              <UploadFileCustom />
            </Form.Item>
            {/* <Form.Item name="updates" label='Update image'>
              <UploadFileCustom />
            </Form.Item> */}
            {replacement_pics_group?.sort((a, b) => a - b)?.map(group => {
              return (<><Form.Item
              key={`pics_${group}`}
              label={`Update Product images ${group}`}
              name='updates'
              required={true}>
              <UploadFileCustom group={+group} accept="image/jpg" />
            </Form.Item></>);
            })}
            <Form.Item name="note" label="Memo">
              <Input.TextArea rows={4} />
            </Form.Item>
          </div>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              return (
                <Button
                  loading={loading}
                  block
                  className="submit-btn"
                  type="primary"
                  htmlType="submit"
                  disabled={!handleValidate(getFieldValue)}>
                  Submit
                </Button>
              );
            }}
          </Form.Item>
        </Form>
      </Content>
    </>
  );
};

export default ReplacementRequest;
