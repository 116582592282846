import { Button, Card, Image } from 'antd';
import React, { useState } from 'react';
import { base64Image } from '../../../asset/images/ImageDefault';
import CopyButton from '../CopyButton';
import './style.scss';
import { truncateString } from '../../../utility/utils';

const SpecialProductCard = ({ data, key, onOpenDescription, haftWidth=false }) => {
  const customStyle = {
    marginRight: '6px',
  };
  if (haftWidth) {
    customStyle['width'] = '50%';
  }
  return (
    <>
      <Card
        key={key}
        bordered={false}
        style={{...customStyle}}
        bodyStyle={{ display: 'flex', fontSize: '11px', position: 'relative' }}
        cover={
          <Image
            style={{
              height: '188px',
              borderRadius: '0',
              objectFit: 'cover'
            }}
            src={data?.product_thumbnail || base64Image}
          />
        }>
        <Card.Grid
          hoverable={false}
          style={{
            // width: 'auto',
            textAlign: 'center',
            padding: '0',
            flex: '1',
            boxShadow: 'none'
          }}>
          <div className="containerOption">
            <div
              className="optionButton"
              style={{
                border: '0',
                boxShadow: 'none',
                height: '40px',
                // backgroundColor: 'transparent',
                width: '100%',
                padding: '4px',
                overflowWrap: 'anywhere',
                overflow: 'hidden',
              }}
              onClick={() => onOpenDescription(data?.special_order_description || '')}>
                {truncateString(data.special_order_description || '', 60)}
              </div>
          </div>
        </Card.Grid>
        <div className="copy-tag">
        <CopyButton
            value={data?.id}
            text={""}
            textNoti={`Copied : Product ID (${data?.id})`}
            isBig={true}
        />
        </div>
        <div className="commission-tag">
          <div>Commission</div>
          <div>
            <b>{data?.special_order_commission ? `€${data?.special_order_commission}` : 'TBD'}</b>
          </div>
        </div>
      </Card>
    </>
  );
};

export default SpecialProductCard;
