import { Select } from 'antd';
import { useCallback } from 'react';
import AsyncSelect from '../../../../common/components/AsyncSelect/AsyncSelect';
import { fetchBrands } from '../../request/api';

export default function BrandAsyncSelect({ ...props }) {
  const fetchBrandsSelect = useCallback(async () => {
    const data = await fetchBrands({ page: 1, size: 500, is_cafe24: false }).then(({ resources = [] }) =>
      resources
        .map((r) => ({
          value: r.id,
          label: r.name_en ? r.name_en : r.name_ko ? r.name_ko : r.id?.toString(),
          label_ko: r.name_ko
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    );
    return data;
  }, []);

  return <AsyncSelect placeholder="Brand" allowClear fetchApi={fetchBrandsSelect} {...props} />;
}
