import { CloseOutlined } from '@ant-design/icons';
import { Card, Drawer, message, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import CopyButton from '../../../../common/components/CopyButton';
import AutoFillButton from '../../../../common/components/AutoFillButton';
import { fetchProductVariants } from '../api';
import '../style.scss';

const OptionsDrawer = ({ onClose, open, id, searchData, onAutoFill, productKey }) => {
  const gridStyle = {
    width: '50%',
    padding: '4px 0 4px 0',
    textAlign: 'center'
  };
  const [optionsData, setOptionsData] = useState([]);
  useEffect(() => {
    if (id) {
      getDataOptions(id, searchData);
    }
  }, [id]);

  const getDataOptions = async (id, params) => {
    try {
      const { data } = await fetchProductVariants(id, params);
      setOptionsData(data.resources);
    } catch (error) {
      message.error(error.response.data.message);
    }
  };
  const OptionCard = ({ data, index }) => {
    return (
      <Card
        title={
          <div className="d-flex justify-content-between">
            <div style={{ fontSize: '14px' }}>
              Option {index + 1} -
              {data?.properties?.length > 0
                ? data?.properties?.map((item, idx) => (
                    <span key={idx}>
                      {item?.property}: {item?.value} {idx !== data?.properties?.length - 1 && '/'}
                    </span>
                  ))
                : 'N/A'}
            </div>
            <div className='d-flex'>
              {onAutoFill && (
                <AutoFillButton value={`${id}-${data?.id}`} onAutoFill={onAutoFill} productKey={productKey} onClose={onClose} />
              )}
              <CopyButton value={`${id}-${data?.id}`} textNoti={`Copied : ${id} - ${data?.id}`}/>
            </div>
          </div>
        }
        // bodyStyle={{ display: 'flex', fontSize: '11px', position: 'relative' }}
      >
        <Card.Grid
          hoverable={false}
          style={{
            ...gridStyle,

            backgroundColor: '#F2F4F7'
          }}>
          Price
        </Card.Grid>
        <Card.Grid hoverable={false} style={gridStyle}>
          € {data?.price}
        </Card.Grid>
        {/* <Card.Grid
          style={{
            ...gridStyle,

            backgroundColor: '#F2F4F7'
          }}>
          Commission
        </Card.Grid>
        <Card.Grid style={gridStyle}>€ {data?.commission}</Card.Grid> */}
        <Card.Grid
          hoverable={false}
          style={{
            ...gridStyle,
            backgroundColor: '#F2F4F7',
            borderRadius: '0 0 0 8px'
          }}>
          Back-order requested
        </Card.Grid>
        <Card.Grid
          hoverable={false}
          style={{
            ...gridStyle,
            backgroundColor: '#FEE4E2',
            borderRadius: '0 0 8px 0'
          }}>
          {data?.requested}
        </Card.Grid>
      </Card>
    );
  };

  return (
    <Drawer
      title={false}
      placement={'bottom'}
      closable={false}
      onClose={onClose}
      open={open}
      rootClassName="optiosDrawer"
      contentWrapperStyle={{
        minHeight: '200px',
        maxHeight: '420px',
        overflow: 'scroll',
      }}>
      <div className="d-flex justify-content-between">
        <p
          style={{
            fontSize: '16px',
            color: '#101828',
            fontWeight: '700'
          }}>
          Option
        </p>
          <CloseOutlined style={{marginBottom:'20px', fontSize:'18px'}} onClick={onClose} />
      </div>
      <div>
        {optionsData?.map((item, index) => {
          return (
            <div key={index} className="mb-3">
              <OptionCard data={item} index={index} />
            </div>
          );
        })}
      </div>
    </Drawer>
  );
};

export default OptionsDrawer;
