import React, { useState } from 'react';
import { ChevronLeft } from 'react-feather';
import UploadSingleImage from '../../../../common/components/UploadSingleImage';
import { Button, Layout, Modal, message } from 'antd';
import HeaderMD2 from '../../../../common/layout/HeaderMD2';
import { Content } from 'antd/es/layout/layout';
import { useDispatch } from 'react-redux';
import { fetchUserInfoThunk } from '../../../../redux/reducers/authSlice';

const RequireStepProfile = ({onChangeStep, data, updateUser }) => {
  const [profileData, setProfileData] = useState(data || null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()
  const onSave = async() => {
    setLoading(true);
    try {
      const res = await updateUser({
        profile_picture: profileData
      });
      dispatch(fetchUserInfoThunk());
      onChangeStep(1);
    } catch (error) {
      message.error(error.response?.data?.message || 'Error');
    } finally {
      setLoading(false);
    }
  };

  const onBack = () => {
    if (profileData) {
      Modal.confirm({
        title: (
          <div className="text-16 fw-7" style={{ color: '#F63D68' }}>
            Warning
          </div>
        ),
        content: (
          <div className="text-14">
            <div>Not saved</div>
            <div>Do you want to save ID card?</div>
          </div>
        ),
        onCancel: () => onChangeStep(1),
        onOk: () => {
          onSave();
        },
        centered: true,
        icon: null,
        cancelText: 'Back',
        okText: 'Save',
        okButtonProps: {
          type: 'primary',
          size: 'large',
          style: {
            // marginRight: '8%',
            width: '48%',
            backgroundColor: '#10264D'
          }
        },
        cancelButtonProps: {
          style: {
            width: '48%'
          },
          size: 'large'
        }
      });
    } else {
      onChangeStep(1);
    }
  };
  return (
    <Layout
      style={{
        backgroundColor: '#fff'
      }}>
      <HeaderMD2 title={''} onNavigate={onBack} />
      <Content
        style={{
          backgroundColor: '#fff',
          paddingBottom: '48px',
          minHeight: 'calc(100vh - 100px)'
        }}>
          <div style={{ padding: '24px', paddingBottom:'80px'}}>
            <div className="text-18 fw-4">
              <b>Take your profile photo</b>
            </div>
            <div className="text-14 gray-700">
              Your profile photo helps people recognise you. Please note that once you have submitted your profile
              photo, it cannot be changed.
            </div>
            <div className="text-14">(1) Face the camera and make sure your eyes and mouth are clearly visible </div>
            <div className="text-14">(2) Make sure the photo is well lit, free of glare and in focus</div>
            <div className="text-14">(3) No photos of a photo, filters or alterations</div>
            <div className="d-flex justify-content-center" style={{ marginTop: '48px' }}>
              <UploadSingleImage value={profileData} onChangeValue={setProfileData} />
            </div>
          </div>
          <Button
            htmlType="submit"
            onClick={onSave}
            className="fixed-bottom-btn"
            block
            type="primary"
            disabled={!profileData}
            loading={loading}
          >
            Save
          </Button>
      </Content>
    </Layout>
  );
};

export default RequireStepProfile;
