import { Button, Col, Empty, Pagination, Row, Select, Spin } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { ChevronDown } from '../../../asset/icons/Icons';
import HeaderMD from '../../../common/layout/HeaderMD';
import { OPTION_STATUS_CASE } from '../../../common/utilities/constants';
import { changePage, fetchCaseStatusThunk, fetchCaseThunk } from '../../../redux/reducers/caseSlice';
import ProductCard from '../../features/purchased/components/ProductCard';
import './style.scss';

const PurchaseProductPage = () => {
  const [selected, setSelected] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const key = searchParams.get('key') || '';
  const { totalResources, page, size, resources, loading, statusOptions } = useSelector((state) => state.cases);

  useEffect(() => {
    setSelected(key);
    dispatch(fetchCaseStatusThunk());
  }, []);

  useEffect(() => {
    if(selected === key){
      dispatch(fetchCaseThunk({ status: selected, page, size }));
    }
  }, [page, selected]);

  const handleChangeSelect = (value) => {
    setSelected(value);
    if (value) {
      navigate({
        search: createSearchParams({
          key: value
        }).toString()
      });
    } else {
      navigate({
        search: createSearchParams({}).toString()
      });
    }
  };

  const handleOnclickSubmitNewCase = () => {
    navigate('/create-case');
  };

  const handleChangePagination = (current) => {
    dispatch(changePage(current));
    // dispatch(fetchRequestThunk({ ...searchData, page: current }));
  };

  return (
    <>
      <HeaderMD title={'Case'} />
      <Content
        style={{
          paddingBottom: '50px'
        }}>
        <div className="container-page">
          <Select
            value={selected}
            onChange={handleChangeSelect}
            style={{
              width: '100%',
              marginTop: '24px',
              marginBottom: '22px',
              border: '1px solid #D0D5DD',
              borderRadius: '5px'
            }}
            suffixIcon={<ChevronDown />}
            bordered={false}
            size="large"
            options={statusOptions}
          />
          <Spin tip="Loading" spinning={loading}>
            {resources?.length > 0 ? (
              <>
                <Row>
                  {resources?.map((item, idx) => {
                    return (
                      <Col key={idx} xs={{ span: 24 }} lg={{ span: 12 }} className="mb-3">
                        <ProductCard data={item} key={idx} />
                      </Col>
                    );
                  })}
                </Row>
                <div className="d-flex justify-content-center mt-1">
                  <Pagination defaultCurrent={page} pageSize={size} total={totalResources} onChange={handleChangePagination} />
                </div>
              </>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </Spin>
        </div>

        <Button className="submit-new-btn" type="primary" block onClick={handleOnclickSubmitNewCase}>
          Submit new Case
        </Button>
      </Content>
    </>
  );
};

export default PurchaseProductPage;
