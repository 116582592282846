export const STATUS_PRODUCT = [
  {
    value: 'pending',
    label: 'Pending',
    color: '#0BA5EC'
  },
  {
    value: 'rejected',
    label: 'Rejected',
    color: '#F63D68'
  },
  {
    value: 'completed',
    label: 'Completed',
    color: '#4BBF9F'
  },
  {
    value: 'requested',
    label: 'Requested',
    color: '#EAAA08'
  },
  {
    value: 'deleted',
    label: 'Deleted',
    color: '#98A2B3'
  }
];

export const STATUS_OF_PRODUCT = [
  {
    value: 'submitted',
    label: 'Submitted',
    color: '#344054'
  },
  {
    value: 'approved',
    label: 'Approved',
    color: '#4BBF9F'
  },
  {
    value: 'refund_submitted',
    label: ' Refund Submitted',
    color: '#F63D68'
  },
  {
    label: 'Replacement Submitted',
    value: 'replacement_submitted',
    color: '#F63D68'
  },
  {
    value: 'refund_requested',
    label: 'Refund requested',
    color: '#FEA3B4'
  },
  {
    label: 'Replacement requested',
    value: 'replacement_requested',
    color: '#FEA3B4'
  },
  {
    label: 'Refunded',
    value: 'refunded',
    color: '#667085'
  },
  {
    label: 'Replace',
    value: 'replace',
    color: '#667085'
  }
];

export const DELIVERY_LOCATION = [
  {
    value: 'france',
    label: 'France'
  },
  {
    value: 'south_korea',
    label: 'Korea'
  }
];

export const REQUESTED_TYPE_DEFAULT = [
  // { value: 'all', label: 'All' },
  { value: 'product_id', label: 'Product ID' },
  { value: 'product_name', label: 'Product Name' }
];

export const REQUESTED_SORT_BY_DEFAULT = [
  {
    value: '-lastly_added',
    label: 'The Newest'
  },
  {
    value: 'lastly_added',
    label: 'The Oldest'
  }
];

export const DEFAULT_QUANTITY_DROPDOWN = [
  ...Array.from({ length: 20 }, (_, i) => ({ value: i + 1, label: `${i + 1}` }))
];

export const OPTION_STATUS_CASE = [
  { value: 'requested', label: 'Requested ' },
  { value: 'rejected', label: 'Rejected ' },
  { value: 'pending', label: 'Pending ' },
  { value: 'completed', label: 'Completed ' }
];
