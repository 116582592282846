import { Col, Form, Row } from 'antd';
import React from 'react';
import { formatCurrency } from '../../../../utility/utils';

const TotalForm = () => {
  function sumQuantity(value) {
    return value?.reduce((acc, cur) => {
      if (cur.quantity) return acc + cur.quantity;
      return acc;
    }, 0);
  }
  function sumSubTotal(value) {
    return value?.reduce((acc, cur) => {
      const quantity = cur.quantity || 0;
      const discount = cur.discount || 0;
      const gross_price = cur.gross_price || 0;
      const percent = cur.vat_percent / 100 + 1;
      const total = parseFloat(quantity * (gross_price - discount) || 0);
      const net = total / percent;
      return acc + net;
    }, 0);
  }

  function sumVat(value) {
    return value?.reduce((acc, cur) => {
      if (cur.vat_percent > 0) {
        const quantity = cur.quantity || 0;
        const discount = cur.discount || 0;
        const gross_price = cur.gross_price || 0;
        const percent = cur.vat_percent / 100 + 1;
        const total = parseFloat(quantity * (gross_price - discount) || 0);
        const net = total / percent;
        const vat = total - net;
        return acc + vat;
      }
      return acc;
    }, 0);
  }
  return (
    <>
      <Row justify="space-between" className="mt-2">
        <Col>Total units</Col>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            return <Col>{sumQuantity(getFieldValue('products'))}</Col>;
          }}
        </Form.Item>
      </Row>
      <Row justify="space-between">
        <Col>Sub total (Net)</Col>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            return <Col>€{formatCurrency(sumSubTotal(getFieldValue('products'))?.toFixed(2))}</Col>;
          }}
        </Form.Item>
      </Row>
      <Row justify="space-between">
        <Col>VAT</Col>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            return <Col>€{formatCurrency(sumVat(getFieldValue('products'))?.toFixed(2))}</Col>;
          }}
        </Form.Item>
      </Row>
      <Row justify="space-between">
        <Col>
          <b>Total</b>
        </Col>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            return (
              <Col>
                <b>
                €{formatCurrency(
                    (sumSubTotal(getFieldValue('products')) + sumVat(getFieldValue('products')))?.toFixed(2)
                  )}
                </b>
              </Col>
            );
          }}
        </Form.Item>
      </Row>
    </>
  );
};

export default TotalForm;
