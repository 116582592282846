import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { capitalize, map, reduce } from 'lodash';
import { fetchCases, fetchCaseStatus } from '../../views/features/purchased/api';

export const fetchCaseThunk = createAsyncThunk('md/case/all', async (params, thunkApi) => {
  return await fetchCases(params, thunkApi);
});
export const fetchCaseStatusThunk = createAsyncThunk('md/case/status/all', async (params, thunkApi) => {
  return await fetchCaseStatus(thunkApi);
});


const initialState = {
  loading: false,
  isOpenSearchDrawer: false,
  totalPage: 0,
  totalResources: 0,
  page: 1,
  size: 50,
  resources: [],
  statusOptions:[]
};

export const caseSlice = createSlice({
  name: 'case',
  initialState,
  reducers: {
    openSearchDrawer: (state) => {
      state.isOpenSearchDrawer = true;
    },
    closeSearchDrawer: (state) => {
      state.isOpenSearchDrawer = false;
    },
    changeSize: (state, action) => {
      state.size = action.payload;
    },
    changePage: (state, action) => {
      state.page = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCaseThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCaseThunk.fulfilled, (state, action) => {
        state.resources = action.payload.resources;
        state.totalPage = action.payload.total_pages;
        state.totalResources = action.payload.total_resources;
        state.loading = false;
      })
      .addCase(fetchCaseThunk.rejected, (state, action) => {
        state.loading = false;
        state.resources = 0;
        state.totalPage = 0;
        state.totalResources = [];
      })
      .addCase(fetchCaseStatusThunk.fulfilled, (state, action) => {
        const status =  action.payload || {}
        const newStatus = map(status, (value, key) => {
          return {
            value: key, 
            label: capitalize(key) + ' ' + `(${value})`
          }
        })
        const sum = reduce(status,(sum, value)  => value + sum , 0)
        newStatus.unshift({
          value: '', 
          label: `All (${sum})`
        })
        state.statusOptions = newStatus ;
      })
  }
});

export const { openSearchDrawer, closeSearchDrawer, changePage, changeSize } = caseSlice.actions;

export default caseSlice.reducer;
