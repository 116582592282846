import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchRequestData, fetchWishData, fetchSpecialOrderData } from '../../views/features/request/api';

export const fetchRequestThunk = createAsyncThunk('md/request/all', async (params, thunkApi) => {
  return await fetchRequestData(params, thunkApi);
});

export const fetchSpecialOrderThunk = createAsyncThunk('md/specialOrder/all', async (params, thunkApi) => {
  return await fetchSpecialOrderData(params, thunkApi);
});

export const fetchWishListThunk = createAsyncThunk('md/wish/all', async (params, thunkApi) => {
  return await fetchWishData(params, thunkApi);
});

const initialState = {
  loading: false,
  isOpenSearchDrawer: false,
  totalPage: 0,
  totalSpecialOrderPage: 0,
  totalResources: 0,
  totalSpecialOrderResources: 0,
  page: 1,
  size: 50,
  specialOrderPage: 1,
  specialOrderSize: 100,
  resources: [],
  specialOrderResources: []
};

export const requestSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    openSearchDrawer: (state) => {
      state.isOpenSearchDrawer = true;
    },
    closeSearchDrawer: (state) => {
      state.isOpenSearchDrawer = false;
    },
    changeSize: (state, action) => {
      state.size = action.payload;
    },
    changePage: (state, action) => {
      state.page = action.payload;
    },
    changeSpecialOrderSize: (state, action) => {
      state.specialOrderSize = action.payload;
    },
    changeSpecialOrderPage: (state, action) => {
      state.specialOrderPage = action.payload;
    },
    wishAll: (state, action) => {
      state.resources = state.resources.map((item) => ({ ...state.resources, wished: true }));
    },
    addWish: (state, action) => {
      state.resources = state.resources.map((item) => {
        if (item.id === action.payload) {
          return {
            ...item,
            in_wishlist: true
          };
        } else return item;
      });
    },
    removeWish: (state, action) => {
      state.resources = state.resources.map((item) => {
        if (item.id === action.payload) {
          return {
            ...item,
            in_wishlist: false
          };
        } else return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRequestThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRequestThunk.fulfilled, (state, action) => {
        state.resources = action.payload.resources;
        state.totalPage = action.payload.total_pages;
        state.totalResources = action.payload.total_resources;
        state.loading = false;
      })
      .addCase(fetchRequestThunk.rejected, (state, action) => {
        state.loading = false;
        state.totalResources = 0;
        state.totalPage = 0;
        state.resources = [];
      })
      .addCase(fetchWishListThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchWishListThunk.fulfilled, (state, action) => {
        state.resources = action.payload.resources;
        state.totalPage = action.payload.total_pages;
        state.totalResources = action.payload.total_resources;
        state.loading = false;
      })
      .addCase(fetchWishListThunk.rejected, (state, action) => {
        state.loading = false;
        state.totalResources = 0;
        state.totalPage = 0;
        state.resources = [];
      })
      .addCase(fetchSpecialOrderThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSpecialOrderThunk.fulfilled, (state, action) => {
        state.specialOrderResources = action.payload.resources;
        state.totalSpecialOrderPage = action.payload.total_pages;
        state.totalSpecialOrderResources = action.payload.total_resources;
        state.loading = false;
      })
      .addCase(fetchSpecialOrderThunk.rejected, (state, action) => {
        state.loading = false;
        state.totalSpecialOrderResources = 0;
        state.totalSpecialOrderPage = 0;
        state.specialOrderResources = [];
      });
  }
});

export const {
  openSearchDrawer,
  closeSearchDrawer,
  changePage,
  changeSpecialOrderPage,
  changeSpecialOrderSize,
  changeSize,
  wishAll,
  addWish,
  removeWish
} = requestSlice.actions;

export default requestSlice.reducer;
