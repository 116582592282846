// ** Redux Imports
import { configureStore } from '@reduxjs/toolkit';
import auth from './reducers/authSlice';
import layout from './reducers/layoutSlice'
import request from './reducers/requestSlice'
import cases from './reducers/caseSlice'
import notification from './reducers/notificationSlice'
import commission  from './reducers/commissionSlice'

const store = configureStore({
  devTools: true,
  reducer: {
    auth,
    layout,
    request,
    cases,
    notification,
    commission
  }
});

export { store };
