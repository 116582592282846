import { Button, Form, Popover, Select } from 'antd';
import React, { useCallback, useState } from 'react';
import { FilterOutlined } from '@ant-design/icons';
import AsyncSelect from '../../../../common/components/AsyncSelect/AsyncSelect';
import { fetchBrands, fetchCategories } from '../api';

const RequestFilter = () => {
  const [open, setOpen] = useState(false);
  const handleOk = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const fetchBrandsSelect = useCallback(async () => {
    const data = await fetchBrands({ page: 1, size: 500, is_cafe24: false }).then(({ resources = [] }) =>
      resources
        .map(r => ({
          value: r.id,
          label: r.name_en,
          label_ko: r.name_ko,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    );
    return data;
  }, []);

  const fetchCategoriesSelect = useCallback(async () => {
    const data = (await fetchCategories({ is_cafe24: false, without_parents: true, page: 1, size: 500 })) || [];
    // Collect categories to material data
    return data.resources
      .map(r => ({
        value: r.id,
        label: r.name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, []);

  return (
    <Popover
      // title="Title"
      placement="bottom"
      content={
        <div
          style={{
            width: '320px'
          }}>
          <p>Brand</p>
          <Form.Item name="brand_id">
            <AsyncSelect
              placeholder="Brand"
              showSearch
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes((input || "").toLowerCase())}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
              allowClear
              fetchApi={fetchBrandsSelect}          
            />
          </Form.Item>
          <p>Category</p>
          <Form.Item name="categories">
          <AsyncSelect
              placeholder="Category"
              mode="multiple"
              allowClear
              fetchApi={fetchCategoriesSelect}           
            />
          </Form.Item>
          <div className="d-flex">
            <Button onClick={handleCancel} block className="me-1">
              Close
            </Button>
            <Button type="primary" className="ms-1" onClick={handleCancel} block>
              Save
            </Button>
          </div>
        </div>
      }
      open={open}
      onConfirm={handleOk}
      onCancel={handleCancel}>
      <Button size="large" className="w-100" icon={<FilterOutlined />} onClick={() => setOpen(true)}>
        Filter
      </Button>
    </Popover>
  );
};

export default RequestFilter;
