import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Drawer, List, message, Modal, Typography, Input } from 'antd';
import { Content } from 'antd/es/layout/layout';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ExternalLinkBlack, ExternalLinkGray, SettingIcon } from '../../../asset/icons/Icons';
import ActionIcon from '../../../common/components/ActionIcons';
import HeaderMD2 from '../../../common/layout/HeaderMD2';
import { fetchNoticesThunk, markRead } from '../../../redux/reducers/notificationSlice';
import { deleteNotice, updateReadNotices } from '../../features/notification/api';
import { apiClientV2 } from '../../../services/axios';
import apiClient from '../../../services/axios';
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);

const NotificationPage = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawerData, setDrawerData] = useState({});
  const [content, setContent] = useState('');
  const [loadingDelete, setLoadingDelete] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { resources, loading } = useSelector((state) => state.notification);
  useEffect(() => {
    dispatch(fetchNoticesThunk());
  }, []);

  const getSColor = (subject) => {
    switch (subject) {
      case 'Completed':
        return '#4BBF9F';
      case 'Approved':
        return '#4BBF9F';
      case 'Rejected':
      case 'Case Deleted':
        return '#F63D68';
      default:
        return '#101828';
    }
  };
  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };
  const getMessage = async (id) => {
    try {
      const res = await apiClientV2.get(`/message/${id}`);
      if (res.status === 200) {
        return res?.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };
  const handleOpenDrawer = async (item) => {
    setDrawerData(item);
    updateStatusOfNotice(item);
    if (
      item.object_type === 'message' ||
      ['case_deleted', 'case_replacement_requested', 'case_refund_requested'].includes(item.type)
    ) {
      if (item.object_type === 'message') {
        const res = await getMessage(item.object_id);
        setContent(res?.content || '');
      } else if (item.content_params?.memo) {
        setContent(item.content_params?.memo);
      } else {
        try {
          const { data } = await apiClient.get(`/case/${item.object_id}`);
          setContent(data?.memo || '');
        } catch (e) {}
      }
      setOpenDrawer(true);
    }
  };

  const updateStatusOfNotice = async (item) => {
    if (!item.read_at) {
      // setLoadingDelete(true);
      try {
        const res = await updateReadNotices(item.id);
        dispatch(markRead(item.id));
        // setLoadingDelete(false);
      } catch (error) {
        message.error(error?.response?.data?.message || 'Error');
        // setLoadingDelete(false);
      }
    }
  };
  const onDeleteNotice = async (id) => {
    try {
      const res = await deleteNotice(id);
      message.success(res.data.message || 'Successfully');
      dispatch(fetchNoticesThunk());
    } catch (error) {
      message.error(error?.response?.data?.message || 'Error');
    }
  };

  const handleDelete = (e, id) => {
    e.stopPropagation();
    Modal.confirm({
      title: 'Are you sure you want to delete it?',
      onCancel: () => {},
      centered: true,
      onOk: () => {
        onDeleteNotice(id);
      },
      icon: null,
      cancelText: 'Cancel',
      okText: 'Delete',
      okButtonProps: {
        danger: true,
        type: 'primary',
        style: {
          marginRight: '8%',
          width: '40%'
        }
      },
      cancelButtonProps: {
        style: {
          width: '40%'
        }
      }
    });
  };

  const handleNavigate = (e, item) => {
    e.stopPropagation();
    updateStatusOfNotice(item);
    const key = item.object_type.split('_')?.[1] || item.object_type;
    let path = 'case';
    switch (key) {
      case 'case':
        path = 'case/detail';
        break;
      default:
        // path = 'case/detail';
        break;
    }
    navigate(`/${path}?id=${item.object_id}`);
  };

  return (
    <>
      <HeaderMD2
        title={'Notification'}
        rightIems={<ActionIcon icon={<SettingIcon />} onClick={() => navigate('/notification/setting')} />}
      />
      <Content
        style={{
          minHeight: 380,
          backgroundColor: '#fff',
          paddingBottom: '10px'
        }}
        className="notification-page">
        <List
          // header={<div>Header</div>}
          // footer={<div>Footer</div>}
          loading={loading}
          dataSource={resources}
          style={{
            borderBottom: '1px solid #D0D5DD'
          }}
          renderItem={(item) => (
            <List.Item
              style={{
                height: '122px',
                backgroundColor: item.read_at ? '#F9FAFB' : '',
                color: item.read_at ? '#D0D5DD' : ''
              }}
              onClick={() => handleOpenDrawer(item)}>
              <div className="w-100 d-flex justify-content-between align-items-center">
                <div>
                  <div
                    className="d-flex"
                    onClick={(e) => {
                      if (item.object_type !== 'message' && !['case_deleted'].includes(item.type)) {
                        handleNavigate(e, item);
                      }
                    }}>
                    <b
                      className="text-decoration-underline"
                      style={{
                        color: item.read_at ? '#D0D5DD' : `${getSColor(item.subject)}`,
                        fontSize: '14px',
                        marginRight: '8px'
                      }}>
                      {item.subject}
                    </b>
                    {item.object_type !== 'message' && !['case_deleted'].includes(item.type) ? (
                      item.read_at ? (
                        <ExternalLinkGray />
                      ) : (
                        <ExternalLinkBlack />
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                  {['case_approved', 'case_replacement_requested', 'case_refund_requested'].includes(item.type) && (
                    <b style={{ color: item.read_at ? '#D0D5DD' : `#101828`, fontSize: '14px' }}>
                      {'Case' + ' ' + item.object_id}
                    </b>
                  )}
                  <Typography.Paragraph ellipsis={{ rows: 2 }} className="text-14" style={{ marginBottom: '0px', whiteSpace: 'break-spaces', overflowWrap: 'anywhere' }}>
                    {item.content}
                  </Typography.Paragraph>
                  <div className="text-12">
                    {dayjs(item?.created_at)?.tz('Africa/Algiers').format('YYYY.MM.DD HH:mm:ss [(GMT+1)]')}
                  </div>
                </div>
                <ActionIcon
                  icon={<DeleteOutlined />}
                  loading={loadingDelete}
                  onClick={(e) => handleDelete(e, item.id)}
                  type="primary"
                  danger
                />
              </div>
            </List.Item>
          )}
        />
      </Content>
      <Drawer
        title={false}
        placement={'bottom'}
        closable={false}
        onClose={handleCloseDrawer}
        open={openDrawer}
        rootClassName="noti-detail"
        contentWrapperStyle={{
          minHeight: '250px',
          height: 'auto',
          maxHeight: '80%',
          overflow: 'scroll',
          borderRadius: '12px 12px 0 0'
        }}>
        <div className="d-flex justify-content-between">
          <p className="text-16">Message</p>
          <CloseOutlined style={{ marginBottom: '20px', fontSize: '18px' }} onClick={handleCloseDrawer} />
        </div>
        <div>
          <p style={{ overflowWrap: 'anywhere', minHeight: '104px' }} className="text-14">
            {content}
          </p>
          <Button block className="mt-3" size="large" onClick={handleCloseDrawer}>
            Close
          </Button>
        </div>
      </Drawer>
    </>
  );
};

export default NotificationPage;
