import { Typography } from 'antd';
import React from 'react';
import { AutoFill } from '../../../asset/icons/Icons';

const AutoFillButton = ({ value, onAutoFill, productKey, onClose }) => {
  return (
    <Typography.Paragraph
      copyable={{
        text: value,
        tooltips: false,
        icon: <AutoFill />,
        onCopy: () => {
          onAutoFill(productKey, value);
          onClose();
        }
      }}
      style={{ marginBottom: '0', width: '100%' }}>
    </Typography.Paragraph>
  );
};

export default AutoFillButton;
