import { getCookie, setCookie, removeCookie } from '../utility/utils';
import { apiClientV2, instance } from './axios';

export const userService = {
  login,
  logout,
  refreshToken,
  verifyRefreshToken
};

async function login(body) {
  const res = await instance.post('auth/login', JSON.stringify(body), {
    headers: { 'Content-Type': 'application/json' }
  });
  const user = res.data;
  // publish user to subscribers and store in local storage to stay logged in between page refreshes

  return user;
}

async function refreshToken() {
  const refreshToken = getCookie('refresh_token');
  try {
    const response = await instance.get('auth/refresh', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(refreshToken)}`
      }
    });
    setCookie('access_token', JSON.stringify(response.data.accessToken));
    setCookie('refresh_token', JSON.stringify(response.data.refreshToken));
    return response.data.accessToken;
  } catch (e) {
    removeCookie('access_token');
    removeCookie('refresh_token');
    location.href = '/login';
    return null;
  }
}

async function verifyRefreshToken() {
  const refreshToken = getCookie('refresh_token');
  let verify = false;
  try {
    const response = await instance.get('auth/refresh/verify', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(refreshToken)}`
      }
    });
    verify = response.data.verify;
  } catch (e) {
    console.log(e);
  }
  if (!verify) {
    removeCookie('access_token');
    removeCookie('refresh_token');
    location.href = '/login';
  }
}

async function logout() {
  // remove user from local storage, publish null to user subscribers and redirect to login page
  const refreshToken = getCookie('refresh_token');
  try {
    const res = await instance.get('auth/logout', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(refreshToken)}`
      }
    });
    return res;
  } catch (e) {
    return e;
  }
}

export const profile = async () => {
  try {
    const response = await apiClientV2.get(`/user/me`);
    if (response.data) return response.data;
    return response;
  } catch (e) {
    return null;
  }
};
