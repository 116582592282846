import { apiClientV2 } from "../../../services/axios";

export const fetchNotices = async () => {

  try {
    const response = await apiClientV2.get('/notification');
    if (response.status === 200) {
      return response.data;
    }
    return thunkApi.rejectWithValue(response);
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
};

export const fetchNotice = async (id) => {
  const response = await apiClientV2.get(`/notification/${id}`);
  return response
};
export const updateReadNotices = async (id) => {
  const response = await apiClientV2.post(`/notification/${id}/read`);
  return response
};

export const deleteNotice = async (id) => {
  const response = await apiClientV2.delete(`/notification/${id}`);
  return response
};