import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { profile, userService } from '../../services/authService';
import { getCookie, removeCookie, setCookie } from '../../utility/utils';

const initialState = {
  userInfo: {},
  getInfoLoading:false,
  loading:false,
  isLogin: getCookie('access_token') ? true : false,
};

export const fetchUserInfoThunk = createAsyncThunk('auth/me', async(thunkAPI)=>{
  try {
    const userData = await profile()
    return userData
  } catch (error) {
    return thunkAPI.rejectWithValue();
  }
})

export const loginThunk = createAsyncThunk('auth/login', async (body) => {
  try {
    const data = await userService.login(body);
    return data
  } catch (error) {
    message.error(error.response.data.message|| 'Login failed')
    return thunkAPI.rejectWithValue();
  }
});

export const logoutThunk = createAsyncThunk('auth/logout', async () => {
  try {
    const data = await userService.logout()
    return data
  } catch (error) {
    message.error(error.response.data.message|| 'Logout failed')
    return thunkAPI.rejectWithValue();
  }
});



export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserInfo: (state,action) => {
      state.userInfo = action.payload
      setCookie('user', action.payload);
    },
    handleLogout: () => {
      removeCookie('user');
      state.userInfo = {}
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loginThunk.pending, (state, action) => {
      state.loading = true
    });
    builder.addCase(loginThunk.fulfilled, (state, action) => {
      setCookie('access_token', JSON.stringify(action.payload.accessToken));
      setCookie('refresh_token', JSON.stringify(action.payload.refreshToken));
      state.isLogin = true
      state.loading = false
    });
    builder.addCase(loginThunk.rejected, (state, action) => {
      state.loading = false
      state.isLogin = false
    });
    builder.addCase(logoutThunk.pending, (state, action) => {
      state.loading = true
    });
    builder.addCase(logoutThunk.fulfilled, (state, action) => {
      removeCookie('access_token');
      removeCookie('refresh_token');
      state.isLogin = false
      state.loading = false
      removeCookie('user');
      state.userInfo = {}
    });
    builder.addCase(logoutThunk.rejected, (state, action) => {
      state.loading = false
    });
    builder.addCase(fetchUserInfoThunk.pending, (state, action)=>{
      state.getInfoLoading = true
    })
    builder.addCase(fetchUserInfoThunk.fulfilled, (state, action)=>{
      state.userInfo = action.payload
      state.getInfoLoading = false
    })
    builder.addCase(fetchUserInfoThunk.rejected, (state, action)=>{
      state.getInfoLoading = false
    })
  }
});

export const { handleLogout,setUserInfo } = authSlice.actions;

export default authSlice.reducer;
