import { Button, Form, Layout, message, Modal, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import CaseInfo from '../../features/purchased/components/CaseInfo';
import TotalForm from '../../features/purchased/components/TotalForm';
import ProductInfo from '../../features/purchased/components/ProductInfo';
import { useNavigate, useSearchParams } from 'react-router-dom';
import apiClient from '../../../services/axios';
import HeaderMD2 from '../../../common/layout/HeaderMD2';
import { DeleteOutlined } from '@ant-design/icons';
const { Content } = Layout;

const CaseDetail = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const caseId = searchParams.get('id');
  const [form] = Form.useForm();
  const [loading, setLoading] = useState();
  const [statusCase, setStatusCase] = useState('');
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  useEffect(() => {
    scrollToTop();
    if (caseId) {
      fetchCaseSubmitted(caseId);
    }
  }, [caseId]);

  const fetchCaseSubmitted = async (id) => {
    setLoading(true);
    try {
      const { data } = await apiClient.get(`/case/${id}`);
      if (data) {
        form.setFieldsValue({
          ...data,
          supplier_brand_id: data.supplier.brand_id,
          products: data.products?.map((item) => ({
            ...item,
            price: item.product?.price,
            product_name: item.product?.name_en?.trim() ? item.product?.name_en : item.product?.name_ko || ''
          }))
        });
        setStatusCase(data.status);
      } else {
        message.error('error');
      }
    } catch (error) {
      message.error(error.response?.data?.message || 'error');
    } finally {
      setLoading(false);
    }
  };

  const deleteCase = async (id) => {
    try {
      const body = {
        status: 'deleted'
      };
      const { data } = await apiClient.put(`/case/${id}`, body);
      if (data) {
        Modal.success({
          title: <div className="text-18">Delete Successful</div>,
          onOk: () => {
            navigate('/case');
          },
          centered: true,
          icon: null,
          okText: 'Close',
          okButtonProps: {
            type: 'default',
            size: 'large',
            style: {
              width: '100%'
            }
          }
        });
      } else {
        message.error(error.response?.data?.message || 'error');
      }
    } catch (error) {
      message.error(error.response?.data?.message || 'error');
    }
  };

  const onEdit = () => {
    navigate(`/edit-case?id=${caseId}`);
  };

  const onDelete = () => {
    Modal.confirm({
      title: <div className="text-18">Are you sure you want to Delete Case?</div>,
      onCancel: () => {},
      onOk: () => {
        deleteCase(caseId);
      },
      centered: true,
      icon: null,
      cancelText: 'Close',
      okText: 'Delete',
      okButtonProps: {
        danger: true,
        type: 'primary',
        size: 'large',
        style: {
          // marginRight: '8%',
          width: '48%',
          backgroundColor: '#f63d68'
        }
      },
      cancelButtonProps: {
        style: {
          width: '48%'
        },
        size: 'large'
      }
    });
  };
  return (
    <>
      <HeaderMD2 
      title={'Case'}
      rightIems={statusCase === 'requested' ? (
        <Button style={{ width: '120px' }}
        icon={<DeleteOutlined style={{ fontSize: '20px' }} />} onClick={onDelete} type="default" block size='large'>
          Delete
        </Button>
      ) : <></>}
       />
      <Content
        style={{
          minHeight: 380,
          backgroundColor: '#fff',
          paddingBottom: statusCase === 'requested' ? '50px' : '10px'
        }}>
        <div className="create-new-case">
          <Typography.Title level={3} className="mt-3" style={{ padding: '0 16px 0 16px' }}>
            Case information
          </Typography.Title>
          <Spin tip="Loading" spinning={loading}>
            <Form form={form} name="caseDetail" layout="vertical" disabled>
              <div className="container-page">
                <CaseInfo mode="view" />
                <ProductInfo mode="view" form={form} />
                <TotalForm />
              </div>
            </Form>
          </Spin>
          {statusCase === 'requested' && (
            <Button onClick={onEdit} className="fixed-bottom-btn" block type="primary">
              Edit Case
            </Button>
          )}
        </div>
      </Content>
    </>
  );
};

export default CaseDetail;
