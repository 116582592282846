import React from 'react';

const Text20 = ({ children, style }) => {
  return (
    <div
      style={{
        fontSize: '20px',
        fontWeight: '700',
        color: '#344054',
        ...style
      }}>
      {children}
    </div>
  );
};

export default Text20;
