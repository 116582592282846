import { Button, Card, Image, Input, Modal, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RightOutlined } from '../../../../asset/icons/Icons';
import { STATUS_OF_PRODUCT, STATUS_PRODUCT } from '../../../../common/utilities/constants';
import { isNumber } from 'lodash';
const { Meta } = Card;

const ProductCard = ({ data }) => {
  const navigate = useNavigate();
  const [popupInfo, setPopupInfo] = useState({
    isOpen: false,
    title: '',
    data: {}
  });
  const { userInfo } = useSelector((state) => state.auth);
  const getStatusTag = (value) => {
    const config = STATUS_PRODUCT.filter((i) => i.value === value)?.[0];
    return <b style={{ color: `${config?.color}`, fontSize: '14px' }}>{config?.label}</b>;
  };

  const getStatusLabel = (value) => {
    const config = STATUS_OF_PRODUCT.filter((i) => i.value === value)?.[0];
    return <b style={{ color: `${config?.color}`, fontSize: '14px' }}>{config?.label}</b>;
  };

  const handleClickRightIcon = () => {
    // if (data?.status === 'requested') {
    navigate(`/case/detail?id=${data?.id}`);
    // }
  };

  const handleReSubmit = (item) => {
    setPopupInfo({
      isOpen: true,
      title: STATUS_OF_PRODUCT.filter((i) => i.value === item?.status)?.[0]?.label,
      data: item
    });
  };
  const handleOkPopup = (item) => {
    if (item.status === 'refund_requested') {
      navigate(`/refund-invoice?id=${item.id}`);
    } else {
      navigate(`/replacement-invoice?id=${item.id}&replacement_pics_group=${item?.last_rejection?.replace_pic_group}`);
    }
  };
  const handleCancelPopup = () => {
    setPopupInfo({
      isOpen: false,
      title: '',
      data: {}
    });
  };

  const handleClickCard = (item) => {
    if (['refund_request', 'replacement_request'].includes(item?.status)) {
      setPopupInfo({
        isOpen: true,
        title: STATUS_OF_PRODUCT.filter(i => i.value === item?.status)?.[0]?.label
      })
    }
  }

  return (
    <>
      <Card
        title={dayjs(data?.updated_at).format('YYYY.MM.DD')}
        extra={
          <div className="d-flex justify-content-between" onClick={handleClickRightIcon}>
            {getStatusTag(data?.status)}
            <div className="ms-2">
              <RightOutlined />
            </div>
          </div>
        }
        bodyStyle={{ padding: '0' }}>
        {data?.products?.map((item, idx) => (
          <div
            key={idx}
            style={{
              padding: '12px',
              borderTop: idx === 0 && '1px solid #D0D5DD',
              borderBottom: idx < data?.products?.length - 1 && '1px solid #D0D5DD',
              backgroundColor: ['refund_requested', 'replacement_requested'].includes(item?.status) && '#FFF1F3'
            }}>
            <div className="d-flex justify-content-between">
              <div>
                <p>{getStatusLabel(item?.status)}</p>
              </div>
              {['refund_requested', 'replacement_requested'].includes(item.status) && (
                <Button type="primary" onClick={() => handleReSubmit(item)}>
                  Resubmit
                </Button>
              )}
            </div>
            <Meta
              avatar={
                <Image
                  style={{
                    width: '76px',
                    height: '76px',
                    objectFit: 'cover',
                    borderRadius: '5px'
                  }}
                  src={item?.product.pictures?.[0]?.file_view || item?.product.extra?.detail_image || ''}
                  alt=""
                />
              }
              // onClick={() => handleClickCard(item)}
              title={<div>{item?.product?.brand?.name_en}</div>}
              description={
                <>
                  <Typography.Paragraph ellipsis={true} style={{ marginBottom: '0' }}>
                    {item?.product?.name_en?.trim() ? item?.product?.name_en : item?.product?.name_ko}
                  </Typography.Paragraph>
                  <Typography.Paragraph ellipsis={true} style={{ marginBottom: '0' }}>{`${
                    item?.product_variant?.product_id
                  }-${item?.product_variant?.id} (${
                    item?.product_variant?.properties?.map((p) => `${p.name} : ${p.value}`).join(' / ') ||
                    'N/A' ||
                    'No variants'
                  })`}</Typography.Paragraph>
                  <div className="d-flex justify-content-between">
                    <div>€{item?.gross_price}</div>
                    {userInfo?.role !== 'md_employee' && (
                      <div>
                        <b>Commission {`${isNumber(item?.commission) ? '€ ' + item?.commission : 'TBD'}`}</b>
                      </div>
                    )}
                  </div>
                </>
              }
            />
          </div>
        ))}
      </Card>
      <Modal
        title={popupInfo?.title}
        open={popupInfo?.isOpen}
        centered
        onCancel={handleCancelPopup}
        footer={
          <div className="d-flex mt-2">
            <Button block onClick={handleCancelPopup}>
              Close
            </Button>
            <Button type="primary" block onClick={() => handleOkPopup(popupInfo?.data)}>
              Modify invoice
            </Button>
          </div>
        }>
        <p>Memo</p>
        <Input.TextArea
          rows={3}
          style={{
            color: '#344054',
            backgroundColor: '#fff'
          }}
          value={popupInfo?.data?.last_rejection?.reason || ''}
          disabled
        />
      </Modal>
    </>
  );
};

export default ProductCard;
