import { Col, Empty, Pagination, Row, Spin } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProductCard from '../../../common/components/ProductCard';
import HeaderMD from '../../../common/layout/HeaderMD';
import {
  changePage,
  closeSearchDrawer,
  fetchWishListThunk,
} from '../../../redux/reducers/requestSlice';
import OptionsDrawer from '../../features/request/components/OptionsDrawer';
import SearchFilter from '../../features/request/components/SearchFilter';

const WishListPage = ({ isHideHeader = false, searchDataProps = {} }) => {
  const [openIsOptionsDrawer, setIsOpenOptionsDrawer] = useState(false);
  const [idProductCur, setIdProductCur] = useState(null);
  const [searchData, setSearchData] = useState({
    sort: '-lastly_added',
    keyword_type: 'product_id',
    size: 50,
    brand_id: searchDataProps.brand_id
  });

  const dispatch = useDispatch();
  const { isOpenSearchDrawer, page, resources, totalResources, loading, size } = useSelector((state) => state.request);

  useEffect(() => {
    dispatch(fetchWishListThunk({ page }));
  }, []);

  const handleCloseOptionDrawer = () => {
    setIsOpenOptionsDrawer(false);
  };
  const handleOpenOptionDrawer = (id) => {
    setIdProductCur(id);
    setIsOpenOptionsDrawer(true);
  };

  const handleChangePagination = (current) => {
    dispatch(changePage(current));
    dispatch(fetchWishListThunk({ page: current }));
  };
  return (
    <>
      {!isHideHeader && <HeaderMD title={'Wishlist'} />}
      <Content>
        <Spin
          tip="Loading"
          spinning={loading}
          style={{
            position: 'fixed',
            top: '20%'
          }}>
          {resources?.length > 0 ? (
            <>
              <Row>
                {resources?.map((item, index) => {
                  return (
                    <Col span={12} key={index}>
                      <ProductCard data={{...item}} onOpenOption={handleOpenOptionDrawer} />
                    </Col>
                  );
                })}
              </Row>
              {/* <div className="d-flex justify-content-center mt-3">
                <Pagination
                  pageSize={size}
                  defaultCurrent={page}
                  total={totalResources}
                  onChange={handleChangePagination}
                />
              </div> */}
            </>
          ) : (
            <Empty className="mt-5" />
          )}
        </Spin>
        <OptionsDrawer id={idProductCur} open={openIsOptionsDrawer} onClose={handleCloseOptionDrawer} />
        {/* <SearchFilter
          open={isOpenSearchDrawer}
          onSaveSearchData={setSearchData}
          searchData={searchData}
          onClose={() => dispatch(closeSearchDrawer())}
        /> */}
      </Content>
    </>
  );
};

export default WishListPage;
