import React, { useState } from 'react';
import { ChevronLeft } from 'react-feather';
import UploadSingleImage from '../../../../common/components/UploadSingleImage';
import { Button, Layout, Modal, message } from 'antd';
import HeaderMD2 from '../../../../common/layout/HeaderMD2';
import { Content } from 'antd/es/layout/layout';
import { useDispatch } from 'react-redux';
import { fetchUserInfoThunk } from '../../../../redux/reducers/authSlice';

const RequireStepKBIS = ({onChangeStep, data, updateUser }) => {
  const [extraitKBIS, setExtraitKBIS] = useState(data || null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()
  const onSave = async() => {
    setLoading(true);
    try {
      const res = await updateUser({
        kbis_picture: extraitKBIS
      });
      dispatch(fetchUserInfoThunk());
      onChangeStep(1);
    } catch (error) {
      message.error(error.response?.data?.message || 'Error');
    } finally {
      setLoading(false);
    }
  };

  const onBack = () => {
    if (extraitKBIS) {
      Modal.confirm({
        title: (
          <div className="text-16 fw-7" style={{ color: '#F63D68' }}>
            Warning
          </div>
        ),
        content: (
          <div className="text-14">
            <div>Not saved</div>
            <div>Do you want to save ID card?</div>
          </div>
        ),
        onCancel: () => onChangeStep(1),
        onOk: () => {
          onSave();
        },
        centered: true,
        icon: null,
        cancelText: 'Back',
        okText: 'Save',
        okButtonProps: {
          type: 'primary',
          size: 'large',
          style: {
            // marginRight: '8%',
            width: '48%',
            backgroundColor: '#10264D'
          }
        },
        cancelButtonProps: {
          style: {
            width: '48%'
          },
          size: 'large'
        }
      });
    } else {
      onChangeStep(1);
    }
  };
  return (
    <Layout
      style={{
        backgroundColor: '#fff'
      }}>
      <HeaderMD2 title={''} onNavigate={onBack} />
      <Content
        style={{
          backgroundColor: '#fff',
          paddingBottom: '48px',
          minHeight: 'calc(100vh - 100px)'
        }}>
        <div style={{ padding: '24px', paddingBottom: '80px' }}>
          <div className="text-18 fw-4">
            <b>Take a photo of your Extrait KBIS (ou relevé SIRENE)</b>
          </div>
          <div className="text-14 gray-700">
            <b>This document must be one of the following types:</b>
          </div>
          <div className="text-14">
            Extrait KBIS, Relevé INSEE, Avis de situation SIRENE, Extrait d’immatriculation CMA.{' '}
          </div>
          <div className="text-14 gray-700">
            <b>Some advice to help you get your document accepted:</b>
            <div className="text-14">
              (1) Make sure the document is legible and that the picture includes all required information
            </div>
            <div className="text-14">(2) Your company must be active, not closed</div>
            <div className="text-14">(3) Your name must be visible on your company’s documentation</div>
          </div>

          <div className="d-flex justify-content-center" style={{ marginTop: '48px' }}>
            <UploadSingleImage value={extraitKBIS} onChangeValue={setExtraitKBIS} />
          </div>
        </div>
        <Button
          htmlType="submit"
          onClick={onSave}
          className="fixed-bottom-btn"
          block
          type="primary"
          disabled={!extraitKBIS}
          loading={loading}
        >
          Save
        </Button>
      </Content>
    </Layout>
  );
};

export default RequireStepKBIS;
