import { Button, Card, Checkbox, Divider, Layout, Radio, Space, Typography, message } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import HeaderMD2 from '../../../../common/layout/HeaderMD2';
import { Content } from 'antd/es/layout/layout';
import ActionIcon from '../../../../common/components/ActionIcons';
import { TouchApp } from '../../../../asset/icons/Icons';
import RequireStepIdCard from './RequireStepIdCard';
import RequireStepProfile from './RequireStepProfile';
import RequireStepKBIS from './RequireStepKBIS';
import { CheckCircle } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { apiClientV2 } from '../../../../services/axios';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserInfoThunk, logoutThunk } from '../../../../redux/reducers/authSlice';
import { find, isEmpty, keyBy } from 'lodash';
import '../style.scss';

const RequireStep = () => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [consentIdCard, setConsentIdCard] = useState(false);
  const [consentProfile, setConsentProfile] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userInfo, getInfoLoading, isLogin } = useSelector((state) => state.auth);

  const picturesData = useMemo(() => {
    return keyBy(userInfo?.pictures || [], 'picture_type');
  }, [userInfo]);

  useEffect(() => {
    if (!isLogin) {
      navigate('/login');
    }else{
      if(isEmpty(userInfo)){
        dispatch(fetchUserInfoThunk());
      }
    }
  }, [isLogin]);

  const updateUser = async (body) => {
    const res = await apiClientV2.put('/user/me', body);
    return res;
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      const res = await updateUser({
        registered: true
      });
      navigate(`/thank-you`);
    } catch (error) {
      message.error(error.response?.data?.message || 'Error');
    } finally {
      setLoading(false);
    }
  };

  if (step === 2) {
    return <RequireStepIdCard onChangeStep={setStep} data={picturesData?.id_card} updateUser={updateUser} />;
  }
  if (step === 3) {
    return <RequireStepProfile onChangeStep={setStep} data={picturesData?.profile} updateUser={updateUser} />;
  }
  if (step === 4) {
    return <RequireStepKBIS onChangeStep={setStep} data={picturesData?.kbis} updateUser={updateUser} />;
  }
  return (
    <Layout
      style={{
        backgroundColor: '#fff'
      }}>
      <HeaderMD2 title={''} onNavigate={()=> dispatch(logoutThunk())} />
      <Content
        style={{
          backgroundColor: '#fff',
          paddingBottom: '48px',
          marginTop: '30px',
          minHeight: 'calc(100vh - 100px)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}>
        <div className="container-page d-flex flex-column">
          <div
            style={{
              fontSize: '32px',
              fontWeight: '400',
              color: '#101828'
            }}>
            Hello
          </div>
          <div
            style={{
              fontSize: '32px',
              fontWeight: '700',
              color: '#4BBF9F'
            }}
            className="mb-2">
            {userInfo?.full_name}
          </div>
          <div
            style={{
              fontSize: '20px',
              fontWeight: '700',
              color: '#101828'
            }}
            className="mb-2">
            Required steps
          </div>
          <div
            style={{
              fontSize: '20px',
              fontWeight: '400',
              color: '#344054'
            }}
            className="mb-2">
            Here’s what you need to do to set up your account
          </div>
          <Space direction="vertical">
            <Card size="small" onClick={() => setStep(2)} className={`${picturesData?.id_card ? 'isValidInfo' : ''}`}>
              <Space>
                {!picturesData?.id_card ? <TouchApp /> : <CheckCircle />}
                <div>Pièce d’ientité en cours de validité (carte d’identité, passport, titre de séjour)</div>
              </Space>
            </Card>
            <Card size="small" onClick={() => setStep(3)} className={`${picturesData?.profile ? 'isValidInfo' : ''}`}>
              <Space>
                {!picturesData?.profile ? <TouchApp /> : <CheckCircle />}
                <div>Votre photo (de face, visage, centré et luminosité suffisante)</div>
              </Space>
            </Card>
            <Card size="small" onClick={() => setStep(4)} className={`${picturesData?.kbis ? 'isValidInfo' : ''}`}>
              <Space>
                {!picturesData?.kbis ? <TouchApp /> : <CheckCircle />}
                <div>Extrait KBIS (ou relevé SIRENE)</div>
              </Space>
            </Card>
          </Space>
        </div>
        <Space direction="vertical" className="w-100">
          <Divider style={{ margin: '0' }} />
          <Radio
            style={{
              padding: '4px 0px 4px 16px'
            }}
            checked={consentIdCard}
            onClick={() => setConsentIdCard((state) => !state)}>
            <Typography.Text underline className="gray-700">
              Consent to collect and use ID
            </Typography.Text>
          </Radio>
          <Divider style={{ margin: '0' }} />
          <Radio
            style={{
              padding: '4px 0px 4px 16px'
            }}
            checked={consentProfile}
            onClick={() => setConsentProfile((state) => !state)}>
            <Typography.Text underline className="gray-700">
              Consent to collect and use personal information
            </Typography.Text>
          </Radio>
        </Space>
        <Button
          onClick={onSubmit}
          className="fixed-bottom-btn"
          style={{
            left: '0'
          }}
          block
          disabled={
            !picturesData?.id_card || !picturesData?.kbis || !picturesData?.profile || !consentIdCard || !consentProfile
          }
          type="primary"
          loading={loading}>
          Submit
        </Button>
      </Content>
    </Layout>
  );
};

export default RequireStep;
