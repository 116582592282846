import { apiClientV2 } from "../../../services/axios";

export async function fetchSupplierList({ sort = '-created_at', page = 1, size = 50, ...other } = {}) {
  try {
    const params = new URLSearchParams();
    params.append('sort', sort);
    params.append('page', page);
    params.append('size', size);
    params.append('keyword_type', 'all');

    if (other.brand_id) {
      params.append('brand_id', +other.brand_id);
    }

    if (other.country) {
      params.append('country', other.country);
    }

    if (other.keyword) {
      params.append('keyword', other.keyword);
    }

    const response = await apiClientV2.get('/supplier', {
      params,
    });

    if (response.status === 200) {
      return response.data;
    }

    return null;
  } catch (e) {
    return null;
  }
}

export const fetchCurrency = async () => {
  try {
    const response = await apiClientV2.get('/currency');
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (e) {
    return null;
  }
};