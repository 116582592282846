import { forEach } from 'lodash';
import apiClient, { apiClientV2 } from '../../../services/axios';

export const fetchRequestData = async (data) => {
  
  const params = new URLSearchParams();
  forEach(data, (value, key) => {
    if (value) {
      if (key !== 'categories') {
        params.append(key, value);
      } else {
        value.forEach((c) => {
          if (c) {
            params.append('categories', c);
          }
        });
      }
    }
  });
  try {
    const response = await apiClient.get('/product-request', {
      params
    });
    if (response.status === 200) {
      return response.data;
    }
    return thunkApi.rejectWithValue(response);
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
};

export const fetchSpecialOrderData = async (data) => {
  
  const params = new URLSearchParams();
  forEach(data, (value, key) => {
    if (value) {
      if (key !== 'categories') {
        params.append(key, value);
      } else {
        value.forEach((c) => {
          if (c) {
            params.append('categories', c);
          }
        });
      }
    }
  });
  try {
    const response = await apiClient.get('/product-request/special-order', {
      params
    });
    if (response.status === 200) {
      return response.data;
    }
    return thunkApi.rejectWithValue(response);
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
};

export const fetchProductVariants = async (id, data) => {
  
  const params = new URLSearchParams();
  forEach(data, (value, key) => {
    if (value) {
      if (key !== 'categories') {
        params.append(key, value);
      } else {
        value.forEach((c) => {
          if (c) {
            params.append('categories', c);
          }
        });
      }
    }
  });
    const response = await apiClient.get(`/product-request/${id}/variants`, {
      params
    });
    return response
   
};


export const fetchBrands = async ({
  page = 1,
  size = 50,
  keyword = '',
  keyword_type = 'all',
  sort = '-created_at',
  is_cafe24 = false
} = {}) => {
  try {
    const response = await apiClientV2.get('/brand', {
      params: {
        page,
        size,
        keyword,
        keyword_type,
        sort,
        is_cafe24
      }
    });

    if (response.status === 200) {
      return response.data;
    }

    return null;
  } catch (e) {
    return null;
  }
};

export const fetchCategories = async ({ page = 1, size = 50, without_parents = false, is_cafe24 = false } = {}) => {
  try {
    const response = await apiClientV2.get('/category', {
      params: {
        without_parents,
        page,
        size,
        is_cafe24
      }
    });

    if (response.status === 200) {
      return response.data;
    }

    return null;
  } catch (e) {
    return null;
  }
};

export const updateAddWish = async (product_id) => {
  return apiClient.post('/wish', {
    product_id
  });
};

export const updateRemoveWish = async (product_id) => {
  return await apiClient.delete(`/wish/product/${product_id}`);
};

export const fetchWishData = async (data) => {
  
  const params = new URLSearchParams();
  forEach(data, (value, key) => {
    if (value) {
      if (key !== 'categories') {
        params.append(key, value);
      } else {
        value.forEach((c) => {
          if (c) {
            params.append('categories', c);
          }
        });
      }
    }
  });
  try {
    const response = await apiClient.get('/product-request/wishlist', {
      params
    });
    if (response.status === 200) {
      return response.data;
    }
    return thunkApi.rejectWithValue(response);
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
};
