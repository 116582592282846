import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const {isLogin, userInfo} = useSelector(state => state.auth)
  if (!isLogin && window.location.pathname !== '/login') {
    return <Navigate to="/login" replace />;
  }
  if(userInfo?.id && !userInfo?.active){
    if(!userInfo?.phone && !userInfo?.registered){
      return <Navigate to="/certify-phone" replace />;
    }
    // else if(userInfo?.phone && !userInfo?.registered){
    //   return <Navigate to="/require-step" replace />;
    // }
    else{
      return <Navigate to="/thank-you" replace />;
    }
  }

  return children;
};

export default PrivateRoute;
