import { LeftOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ActionIcon from '../components/ActionIcons/index';
import './style.scss';
import { ChevronLeft } from 'react-feather';
const { Header } = Layout;

const HeaderMD2 = ({ title, rightIems, onNavigate, isHideLeftIcon }) => {
  const navigate = useNavigate();
  return (
    <Header
      style={{
        position: 'sticky',
        top: 0,
        zIndex: 50,
        width: '100%',
        backgroundColor: '#ffff',
        display: 'flex',
        padding: '0 5vw 0 5vw',
        justifyContent: 'space-between',
        boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.15)'
      }}>
      <div className="d-flex justify-content-between w-100">
        <div className="me-1 d-flex align-items-center">
          {!isHideLeftIcon ? (
            <ActionIcon
              icon={<ChevronLeft />}
              onClick={() => {
                if (onNavigate) {
                  onNavigate();
                } else {
                  navigate(-1);
                }
              }}
            />
          ) : (
            <></>
          )}
          <p className="title">{title}</p>
        </div>
        <div className="d-flex align-items-center">
          {rightIems && <div className="d-flex align-items-center">{rightIems}</div>}
        </div>
      </div>
    </Header>
  );
};

export default HeaderMD2;
