import { Button, Layout, Modal, message } from 'antd';
import React, { useState } from 'react';
import { ChevronLeft } from 'react-feather';
import UploadSingleImage from '../../../../common/components/UploadSingleImage';
import HeaderMD2 from '../../../../common/layout/HeaderMD2';
import { Content } from 'antd/es/layout/layout';
import { useDispatch } from 'react-redux';
import { fetchUserInfoThunk } from '../../../../redux/reducers/authSlice';

const RequireStepIdCard = ({updateUser, data, onChangeStep }) => {
  const [idCardData, setIdCardData] = useState(data|| null)
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()
  const onSave = async () => {
    setLoading(true);
    try {
      const res = await updateUser({
        idcard_picture: idCardData
      });
      onChangeStep(1);
      dispatch(fetchUserInfoThunk());
    } catch (error) {
      message.error(error.response?.data?.message || 'Error');
    } finally {
      setLoading(false);
    }
  };

  const onBack = () => {
    if (idCardData) {
      Modal.confirm({
        title: (
          <div className="text-16 fw-7" style={{ color: '#F63D68' }}>
            Warning
          </div>
        ),
        content: (
          <div className="text-14">
            <div>Not saved</div>
            <div>Do you want to save ID card?</div>
          </div>
        ),
        onCancel: () => onChangeStep(1),
        onOk: () => {
          onSave();
        },
        centered: true,
        icon: null,
        cancelText: 'Back',
        okText: 'Save',
        okButtonProps: {
          type: 'primary',
          size: 'large',
          style: {
            // marginRight: '8%',
            width: '48%',
            backgroundColor: '#10264D'
          }
        },
        cancelButtonProps: {
          style: {
            width: '48%'
          },
          size: 'large'
        }
      });
    } else {
      onChangeStep(1);
    }
  };

  return (
    <Layout
      style={{
        backgroundColor: '#fff'
      }}>
      <HeaderMD2 title={''} onNavigate={onBack} />
      <Content
        style={{
          backgroundColor: '#fff',
          paddingBottom: '48px',
          minHeight: 'calc(100vh - 100px)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}>
        <div style={{ padding: '24px', paddingBottom: '80px' }}>
          <div className="text-18 fw-4">
            Take a photo of your <b>ID card</b> or <b>Passport</b> or <b>Residence permit</b>
          </div>
          <div className="text-14 gray-700">
            <b>For the document to be validated:</b>
          </div>
          <div className="text-14">(1) Take a photo of the original document. A photocopy is not acceptable. </div>
          <div className="text-14">
            (2) Make sure all information is legible, not blurry and that all corners of the document are visible.
          </div>
          <div className="d-flex justify-content-center" style={{ marginTop: '48px' }}>
            <UploadSingleImage value={idCardData} onChangeValue={setIdCardData} />
          </div>
        </div>
        <Button
          htmlType="submit"
          onClick={onSave}
          className="fixed-bottom-btn"
          block
          type="primary"
          disabled={!idCardData}
          loading={loading}
        >
          Save
        </Button>
      </Content>
    </Layout>
  );
};

export default RequireStepIdCard;
