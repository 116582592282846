import { LeftOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import ActionIcon from '../components/ActionIcons/index';
import './style.scss';
const { Header, Content } = Layout;

const HTVLayout2 = () => {
  const [selectedKey, setSelectedKey] = useState('refund-invoice');
  const navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    if (location?.pathname && location?.pathname !== selectedKey) {
      setSelectedKey(location?.pathname?.split('/')?.[1]);
    }
  }, []);
  const titlesHeader = [
    {
      label: 'Refund invoice',
      key: 'refund-invoice'
    },
    {
      label: 'Replacement invoice',
      key: 'replacement-invoice'
    },
    {
      label: 'Submit New Case',
      key: 'create-case'
    },
    {
      label:'Case',
      key:'case'
    }
  ];
  const getTitleHeader = (key) => {
    return titlesHeader?.filter((item) => item?.key === key)?.[0]?.label || 'refund-invoice';
  };

  return (
    <>
      <Layout>
          <Outlet />
      </Layout>
    </>
  );
};

export default HTVLayout2;
