import { Badge, Layout, Space } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BellIcon, MenuBar, NoticeIcon } from '../../asset/icons/Icons';
import { openSideBar } from '../../redux/reducers/layoutSlice';
import { fetchNumberOfNoticesThunk } from '../../redux/reducers/notificationSlice';
import ActionIcon from '../components/ActionIcons/index';
import './style.scss';
const { Header, Content } = Layout;

const HeaderMD = ({ title, rightIems }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {unRead} = useSelector(state => state.notification)
  useEffect(() => {
    let getNotices = setInterval(()=>{
      dispatch(fetchNumberOfNoticesThunk());
    },15000)

    return function cleanUp(){
      clearInterval(getNotices)
    }
  }, []);
  return (
    <Header
      style={{
        position: 'sticky',
        top: 0,
        zIndex: 50,
        width: '100%',
        backgroundColor: '#ffff',
        display: 'flex',
        padding: '0 5vw 0 5vw',
        justifyContent: 'space-between',
        boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.15)'
      }}>
      <div className="d-flex justify-content-between w-100">
        <div className="d-flex">
          <div className="me-1">
            <ActionIcon icon={<MenuBar />} onClick={() => dispatch(openSideBar())} />
          </div>
          <p className="title">{title}</p>
        </div>
        <Space align="center" size='middle'>
          {rightIems && <div>{rightIems}</div>}
          <div className='d-flex align-items-center' >
            <Badge count={unRead || 0} onClick={() => navigate('/notification')}>
              <BellIcon  />
            </Badge>
          </div>
        </Space>
      </div>
    </Header>
  );
};

export default HeaderMD;
