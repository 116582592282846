import { LoadingOutlined } from '@ant-design/icons';
import { Button, Card, Image, Upload } from 'antd';
import React, { useState } from 'react';
import { ImageIcon } from '../../asset/icons/Icons';
import { uploadFiles } from '../api';

const UploadSingleImage = ({value, onChangeValue}) => {
  const [uploading, setUploading] = useState(false);
  const uploadImage = async (files) => {
    const payload = {
      feature_type: 'md_case',
      files: files
    };
    setUploading(true);
    const res = await uploadFiles(payload);
    if (res?.data) {
      onChangeValue(res.data?.[0]);
    }
    setUploading(false);
  };
  const props = {
    name: 'avatar',
    listType: 'picture-card',
    className: 'avatar-uploader',
    showUploadList: false,
    // onRemove: (file) => {
    //   const index = fileList.indexOf(file);
    //   const newFileList = fileList.slice();
    //   newFileList.splice(index, 1);
    //   setFileList(newFileList);
    // },
    beforeUpload: (file) => {
      // setFileList([file]);
      uploadImage([file]);
      return false;
    }
    // fileList
  };

  const onDelete = () => {
    onChangeValue(null);
    // setFileList([])
  };

  return (
    
      <div
        style={{
          width: '240px',
          height: '240px'
        }}>
        {!value ? (
          <Upload.Dragger
            {...props}
            style={{
              border: 'none',
              background: '#fff'
            }}

            // beforeUpload={beforeUpload}
            // onChange={handleChange}
          >
            <Card
              style={{
                width: '240px',
                height: '240px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <div>
                {uploading ? (
                  <LoadingOutlined />
                ) : (
                  <>
                    <ImageIcon size={50} />
                    <div style={{ marginTop: 8 }}>Take a photo</div>
                  </>
                )}
              </div>
            </Card>
          </Upload.Dragger>
        ) : (
          <>
            <Image
              width={240}
              height={240}
              style={{ borderRadius: '6px', borderColor: '#344054' }}
              src={
                value.file_view ||
                'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
              }
            />
            <Button size="large" block style={{ marginTop: '20px' }} onClick={onDelete}>
              Delete
            </Button>
          </>
        )}
      </div>
  );
};

export default UploadSingleImage;
