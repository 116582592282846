import { Button, Col, Form, Grid, Input, Layout, message, Modal, Row, Select, Space } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import HeaderMD2 from '../../../../common/layout/HeaderMD2';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { apiClientV2 } from '../../../../services/axios';
import { loginThunk } from '../../../../redux/reducers/authSlice';
import { useDispatch, useSelector } from 'react-redux';

const OPTIONS_PHONE = [
  {
    value: 'korean',
    label: 'Korea +82'
  }
];

const regX_password = /^(?=.*[0-9])(?=.*[!@#$&*'%&()|^?><]).{8,}$/;

const CONDITION_PASSWORD_DEFAULT = [
  {
    key: 1,
    description: 'At least 8 characters',
    regX: /.{8,}$/,
    isValid: false
  },
  {
    key: 2,
    description: 'At least 1 number',
    regX: /\d/,
    isValid: false
  },
  {
    key: 3,
    description: 'At least 1 special characters',
    regX: /.*[!@#$&*'%&()|^?><]/,
    isValid: false
  }
];

const getOptionsValidPassword = (value) => {
  return CONDITION_PASSWORD_DEFAULT.map((item) => ({
    ...item,
    isValid: item.regX?.test(value)
  }));
};

const SignUpPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { loading, isLogin } = useSelector((state) => state.auth);
  const [openModal, setOpenModal] = useState(false);
  const [signUpLoading, setSignUpLoading] = useState(false);

  const isCheckValidForm = (values) => {
    const isValidPassword = values.password && regX_password.test(values?.password);
    return (
      values?.full_name &&
      isValidPassword &&
      values?.username &&
      values?.confirm_password &&
      values?.confirm_password === values.password
    );
  };

  useEffect(() => {
    if (isLogin) {
      setOpenModal(false);
      navigate(`/certify-phone`);
    }
  }, [isLogin]);

  const signUp = async (body) => {
    setSignUpLoading(true);
    try {
      const { data } = await apiClientV2.post('/auth/signup', body);
      setSignUpLoading(false);
      setOpenModal(true);
    } catch (error) {
      setSignUpLoading(false);
      message.error(error.response.data?.message || 'Sign up failed');
    }
  };

  const handleSignUp = (values) => {
    const { full_name, username, password } = values;
    signUp({
      full_name,
      username,
      password,
      role: 'no_authority'
    });
  };

  const handleSignIn = () => {
    const { username, password } = form.getFieldsValue();
    dispatch(
      loginThunk({
        username,
        password
      })
    );
  };

  return (
    <Layout
      style={{
        backgroundColor: '#fff',
        paddingBottom: '10px'
      }}>
      <HeaderMD2 title={'Sign Up'} onNavigate={() => navigate('/login')} />
      <Content
        style={{
          backgroundColor: '#fff',
          marginTop: '20px'
        }}>
        <div className="container-page d-flex flex-column" style={{ minHeight: 'calc(100vh - 120px)' }}>
          <Form
            form={form}
            name="signIn"
            initialValues={{
              full_name: null,
              username: null,
              password: null,
              confirm_password: null
            }}
            onFinish={handleSignUp}>
            <Form.Item name="full_name" label={<div className="text-14 gray-900 fw-5">User Name</div>}>
              <Input placeholder="Enter User Name" size="large" />
            </Form.Item>
            <Form.Item name="username" label={<div className="text-14 gray-900 fw-5">User ID</div>}>
              <Input placeholder="Enter User ID" size="large" />
            </Form.Item>
            <Form.Item
              name="password"
              label={<div className="text-14 gray-900 fw-5">Password</div>}
              style={{ marginBottom: '8px' }}>
              <Input.Password placeholder="Password" size="large" />
            </Form.Item>
            <Form.Item shouldUpdate={(preValue, curValue) => preValue.password !== curValue.password}>
              {({ getFieldValue }) => {
                const config = getOptionsValidPassword(getFieldValue('password'));
                return config.map((item) => (
                  <div key={item.item} className="d-flex" style={{ color: item.isValid ? '#1570EF' : '#98A2B3' }}>
                    {item.isValid ? <CheckOutlined /> : <CloseOutlined />}{' '}
                    <div className="ms-1">{item.description}</div>
                  </div>
                ));
              }}
            </Form.Item>
            <Form.Item name="confirm_password" style={{ marginBottom: '8px' }}>
              <Input.Password placeholder="Confirm Password" size="large" />
            </Form.Item>
            <Form.Item shouldUpdate={(preValue, curValue) => preValue.confirm_password !== curValue.confirm_password}>
              {({ getFieldValue }) => {
                const item = {
                  key: 4,
                  description: 'Password matching',
                  isValid:
                    getFieldValue('confirm_password') && getFieldValue('password') == getFieldValue('confirm_password')
                };
                return (
                  <div key={item.item} className="d-flex" style={{ color: item.isValid ? '#1570EF' : '#98A2B3' }}>
                    {item.isValid ? <CheckOutlined /> : <CloseOutlined />}{' '}
                    <div className="ms-1">{item.description}</div>
                  </div>
                );
              }}
            </Form.Item>
            <Form.Item shouldUpdate>
              {({ getFieldsValue }) => (
                <Button
                  block
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={signUpLoading}
                  disabled={!isCheckValidForm(getFieldsValue())}>
                  Sign Up
                </Button>
              )}
            </Form.Item>
          </Form>
          <div className="text-14 gray-700 fw-4 mt-4 text-center">
            Already have an account?{' '}
            <b className="text-decoration-underline" size="large" onClick={() => navigate('/login')}>
              Sign In
            </b>
          </div>
        </div>
      </Content>
      <Modal
        closable={false}
        maskClosable={false}
        open={openModal}
        footer={false}
        centered
        title={<div className="text-16 gray-900 fw-7">Successful sign in</div>}>
        <div className="text-14 gray-700 ">
          Please select the <b>{`"Sign in"`}</b> button below to log in right away
        </div>
        <div className="w-100 mt-4 d-flex">
          <Button block onClick={() => navigate('/login')} className="me-3" size="large">
            Close
          </Button>
          <Button block type="primary" onClick={handleSignIn} loading={loading} size="large">
            Sign In
          </Button>
        </div>
      </Modal>
    </Layout>
  );
};

export default SignUpPage;
