import React from 'react';

const Text14 = ({ children, style }) => {
  return (
    <div
      style={{
        fontSize:'14px',
        fontWeight:'400',
        color:'#667085',
        ...style
      }}>
      {children}
    </div>
  );
};

export default Text14;