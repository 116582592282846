import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiClient, { apiClientV2 } from '../../services/axios';

export const fetchCommissionHistory = async (params,thunkApi) => {
  try {
    const response = await apiClient.get('/commission',{params});
    if (response.status === 200) {
      return response.data;
    }
    return thunkApi.rejectWithValue(response);
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
};

export const fetchCommissionTotal = async (thunkApi) => {
  try {
    const response = await apiClient.get(`/commission/agg`);
    if (response.status === 200) {
      return response.data;
    }
    return thunkApi.rejectWithValue(response);
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
};

export const fetchCommissionHistoryThunk = createAsyncThunk('md/commission-history/all', async (params, thunkApi) => {
  return await fetchCommissionHistory(params, thunkApi);
});

export const fetchCommissionTotalThunk = createAsyncThunk('md/commission-total/all', async (id, thunkApi) => {
  return await fetchCommissionTotal(id, thunkApi);
});

const initialState = {
  loading: false,
  resources: [],
  totalPages:null,
  totalResources: null,
  totalCommission:null,
  remainingCommission:null
};

export const commissionSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCommissionHistoryThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCommissionHistoryThunk.fulfilled, (state, action) => {
        state.resources = action.payload.resources;
        state.totalPages=action.payload.total_pages;
        state.totalResources=action.payload.total_resources;
        state.loading = false;
      })
      .addCase(fetchCommissionHistoryThunk.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(fetchCommissionTotalThunk.fulfilled, (state, action) => {
        state.totalCommission = action.payload.total_commission;
        state.remainingCommission=action.payload.remaining_commission;
      })
  }
});

export const {  } = commissionSlice.actions;

export default commissionSlice.reducer;
