import { Button, Col, Empty, message, Pagination, Row, Spin, Carousel, Drawer } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ActionIcon from '../../../common/components/ActionIcons';
import ProductCard from '../../../common/components/ProductCard';
import SpecialProductCard from '../../../common/components/SpecialProductCard';
import HeaderMD from '../../../common/layout/HeaderMD';
import { Text16 } from '../../../common/components/text';
import {
  changePage,
  changeSpecialOrderPage,
  closeSearchDrawer,
  fetchRequestThunk,
  fetchSpecialOrderThunk,
  openSearchDrawer
} from '../../../redux/reducers/requestSlice';
import OptionsDrawer from '../../features/request/components/OptionsDrawer';
import SearchFilter from '../../features/request/components/SearchFilter';
import { HeartAll, HeartAllFill } from '../../../asset/icons/Icons';
import { Search } from 'react-feather';

const RequestPage = ({ isHideHeader = false, onAutoFill, productKey, searchDataProps = {} }) => {
  const [openIsOptionsDrawer, setIsOpenOptionsDrawer] = useState(false);
  const [idProductCur, setIdProductCur] = useState(null);
  const [isWishList, setIsWishList] = useState(false);
  const [openSpecialDrawer, setOpenSpecialDrawer] = useState(false);
  const [specialDescription, setSpecialDescription] = useState(null);
  const [searchData, setSearchData] = useState({
    sort: '-lastly_added',
    keyword_type: 'product_id',
    size: 50,
    brand_id: searchDataProps.brand_id
  });

  const dispatch = useDispatch();
  const {
    isOpenSearchDrawer,
    page,
    specialOrderPage,
    resources,
    specialOrderResources,
    totalResources,
    totalSpecialOrderResources,
    loading,
    size,
    specialOrderSize
  } = useSelector((state) => state.request);

  useEffect(() => {
    dispatch(
      fetchRequestThunk({
        page,
        ...searchData,
        in_wishlist: isWishList || searchDataProps?.in_wishlist || false
      })
    );
  }, [isWishList, searchDataProps?.in_wishlist]);

  useEffect(() => {
    dispatch(
      fetchSpecialOrderThunk({
        page: specialOrderPage,
        size: specialOrderSize,
        ...searchData,
      })
    );
  }, []);

  const handleCloseOptionDrawer = () => {
    setIsOpenOptionsDrawer(false);
  };
  const handleOpenOptionDrawer = (id) => {
    setIdProductCur(id);
    setIsOpenOptionsDrawer(true);
  };

  const handleChangePagination = (current) => {
    dispatch(changePage(current));
    dispatch(
      fetchRequestThunk({
        in_wishlist: isWishList || searchDataProps?.in_wishlist || false,
        ...searchData,
        page: current
      })
    );
  };

  const handleChangeSpecialOrderPagination = (current) => {
    dispatch(changeSpecialOrderPage(current));
    dispatch(fetchSpecialOrderThunk({ page: current, size: specialOrderSize }));
  };

  const handleCloseSpecialDrawer = () => {
    setOpenSpecialDrawer(false);
  };

  const handleOpenSpecialDrawer = async (item) => {
    setSpecialDescription(item);
    setOpenSpecialDrawer(true);
  };

  return (
    <>
      {!isHideHeader && (
        <HeaderMD
          title={'Request'}
          rightIems={
            <>
              <ActionIcon
                style={{ padding: '0px', marginRight: '10px' }}
                icon={<Search size="26px" />}
                onClick={() => dispatch(openSearchDrawer())}
              />
              {isWishList ? (
                <ActionIcon
                  icon={<HeartAllFill />}
                  style={{ padding: '0px', marginTop: '19px' }}
                  onClick={() => {
                    setIsWishList((state) => !state);
                    dispatch(changePage(1));
                  }}></ActionIcon>
              ) : (
                <ActionIcon
                  icon={<HeartAll />}
                  style={{ position: 'relative', padding: '0px', marginTop: '19px' }}
                  onClick={() => {
                    setIsWishList((state) => !state);
                    dispatch(changePage(1));
                    }}>
                  <span style={{ position: 'absolute', color: '#FFFFFF', fontSize: '12px', left: '9px' }}>All</span>
                </ActionIcon>
              )}
            </>
          }
        />
      )}
      <Content>
        <Spin
          tip="Loading"
          spinning={loading}
          style={{
            position: 'fixed',
            top: '20%'
          }}>
          {resources?.length > 0 ? (
            <>
              {specialOrderResources?.length > 0 && (
                <>
                  <div className="d-flex justify-content-between" style={{ margin: '10px 0px 10px 20px' }}>
                    <Text16>Special Order</Text16>
                  </div>
                  <Carousel 
                  dotPosition="none" 
                  slidesToShow={specialOrderResources?.length == 1 ? 1 : 2}
                  slidesToScroll={1}
                  centerMode={true}
                  centerPadding={'20px'}
                  className="custom-carousel"
                  >
                    {specialOrderResources?.map((item, index) => {
                      return (
                        // <Col span={12} key={index}>
                        <SpecialProductCard key={index} data={item} onOpenDescription={handleOpenSpecialDrawer} haftWidth={specialOrderResources?.length == 1} />
                        // </Col>
                      );
                    })}
                  </Carousel>
                </>
              )}
              <div className="d-flex justify-content-between" style={{ margin: '10px 0px 10px 20px' }}>
                <Text16>Requested</Text16>
              </div>
              <Row>
                {resources?.map((item, index) => {
                  return (
                    <Col span={12} key={index}>
                      <ProductCard data={item} onOpenOption={handleOpenOptionDrawer} />
                    </Col>
                  );
                })}
              </Row>
              <div className="d-flex justify-content-center mt-3">
                <Pagination
                  pageSize={size}
                  defaultCurrent={page}
                  total={totalResources}
                  onChange={handleChangePagination}
                />
              </div>
            </>
          ) : (
            <Empty className="mt-5" />
          )}
        </Spin>
        <OptionsDrawer id={idProductCur} open={openIsOptionsDrawer} onClose={handleCloseOptionDrawer} onAutoFill={onAutoFill} productKey={productKey} />
        <SearchFilter
          open={isOpenSearchDrawer}
          onSaveSearchData={setSearchData}
          searchData={searchData}
          in_wishlist={isWishList || searchDataProps?.in_wishlist || false}
          onClose={() => dispatch(closeSearchDrawer())}
        />
        <Drawer
          title={false}
          placement={'bottom'}
          closable={false}
          onClose={handleCloseSpecialDrawer}
          open={openSpecialDrawer}
          rootClassName="noti-detail"
          contentWrapperStyle={{
            minHeight: '210px',
            height: 'auto',
            maxHeight: '80%',
            overflow: 'scroll',
            borderRadius: '12px 12px 0 0'
          }}>
          <div>
            <p style={{ overflowWrap: 'anywhere', minHeight: '104px' }} className="text-14">
              {specialDescription}
            </p>
            <Button block className="mt-3" size="large" onClick={handleCloseSpecialDrawer}>
              Close
            </Button>
          </div>
        </Drawer>
      </Content>
    </>
  );
};

export default RequestPage;
